import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import BackIcon from '../assets/img/icons/back-icon.png';
import AppLogo from '../assets/img/logotype.png';
import { getUserScores } from '../redux/actions/leaderboardActions';
import { createWorkoutSession } from '../redux/actions/workoutActions';

const FinishedWorkout = (props: {}) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { workout } = useSelector((state: RootState) => state.workouts);
    const { watchedVideos } = useSelector((state: RootState) => state.workouts);
    const { userScore } = useSelector((state: RootState) => state.leaderboard);
    const [duration, setDuration] = useState<number>(0);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<string>('');

    useEffect(() => {
        setDuration(watchedVideos.reduce((acc, curr) => acc + curr.watchedTime, 0));
    }, [watchedVideos]);

    useEffect(() => {
        dispatch(getUserScores());
    }, [dispatch]);

    const handleSelect = useCallback(
        (e) => {
            setIsSubmitted(true);
            setFeedback(e.target.value);
            dispatch(
                createWorkoutSession({
                    workout_id: parseInt(workout.id),
                    video_id: 418,
                    total_workouts: watchedVideos.length,
                    total_points: userScore.points,
                    strake_days: userScore.best_streak,
                    feedback: e.target.value,
                    workout_duration: Math.floor(duration),
                })
            );
        },
        [dispatch, duration, userScore.best_streak, userScore.points, watchedVideos.length, workout.id]
    );

    // const handleLogWeight = useCallback(() => {
    //     navigate(`/workout/${workout.id}/video/log-weight`);
    // }, [navigate, workout.id]);

    return (
        <div className="w-screen h-dvh bg-smoke-blue bg-cover bg-bottom flex flex-col items-center justify-evenly">
            <div className='h-full w-full md:w-10/12 lg:w-7/12 xl:w-5/12 flex flex-col node-smoke-blue font-["SF-Pro-Display"] tracking-wider'>
                <div className="h-1/5 flex items-center justify-center">
                    <div className="h-full flex items-center w-2/3">
                        <Link to={`/workout/${workout.id}`} className="absolute left-10 top-10">
                            <div className="bg-black rounded-full">
                                <img alt="back" src={BackIcon} className="w-18" />
                            </div>
                        </Link>
                        <div className="flex flex-col items-center text-center">
                            <img alt="sweatsonic" src={AppLogo} className="w-full" />
                            <div className="divider divider-secondary my-0" />
                            <span className="text-white">Let's Go!</span>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center">
                    <p className="text-white text-lg">That Was Your 100th Workout!</p>
                </div>
                <div className="w-full h-4/5 flex flex-col p-4">
                    <ul className="flex flex-col w-full border-secondary border-2 rounded-lg p-4 space-y-2">
                        <li className="w-full flex justify-between">
                            <p className="text-white">Duration</p>
                            <span>
                                {Math.floor(duration / 60)}:
                                {Math.floor(duration % 60)
                                    .toString()
                                    .padStart(2, '0')}{' '}
                                minutes
                            </span>
                        </li>
                        <li className="w-full flex justify-between">
                            <p className="text-white">Current Streak</p>
                            <span>{userScore.best_streak}</span>
                        </li>
                        <li className="w-full flex justify-between">
                            <p className="text-white">Total Workouts</p>
                            <span>{userScore.workout_session_count}</span>
                        </li>
                    </ul>
                    {/* <div className="p-2 w-full">
                        <button onClick={handleLogWeight} className="w-full btn btn-outline btn-secondary rounded-full">
                            Log Weight?
                        </button>
                    </div> */}
                    <div className="p-2 w-full flex flex-col items-center">
                        <p className="text-white text-lg p-2">How was that for you?</p>
                        <form className="flex flex-col justify-around space-y-3 w-full">
                            <label
                                className={`btn hover:btn-secondary rounded-full ${isSubmitted && feedback !== 'EASY' ? 'btn-disabled' : ''} ${feedback === 'EASY' ? 'btn-secondary pointer-events-none cursor-default' : 'btn-outline'}`}
                            >
                                <span className="">Too Easy</span>
                                <input
                                    type="radio"
                                    checked={feedback === 'Easy'}
                                    className="hidden"
                                    value={'EASY'}
                                    onChange={(e) => handleSelect(e)}
                                />
                            </label>
                            <label
                                className={`btn hover:btn-secondary rounded-full ${isSubmitted && feedback !== 'MEDIUM' ? 'btn-disabled' : ''} ${feedback === 'MEDIUM' ? 'btn-secondary pointer-events-none cursor-default' : 'btn-outline'}`}
                            >
                                <span className="">Medium</span>
                                <input
                                    type="radio"
                                    checked={feedback === 'Medium'}
                                    className="hidden"
                                    value={'MEDIUM'}
                                    onChange={(e) => handleSelect(e)}
                                />
                            </label>
                            <label
                                className={`btn hover:btn-secondary rounded-full ${isSubmitted && feedback !== 'HARD' ? 'btn-disabled' : ''} ${feedback === 'HARD' ? 'btn-secondary pointer-events-none cursor-default' : 'btn-outline'}`}
                            >
                                <span className="">Hard</span>
                                <input
                                    type="radio"
                                    checked={feedback === 'Hard'}
                                    className="hidden"
                                    value={'HARD'}
                                    onChange={(e) => handleSelect(e)}
                                />
                            </label>
                            <label
                                className={`btn hover:btn-secondary rounded-full ${isSubmitted && feedback !== 'TOO_HARD' ? 'btn-disabled' : ''}  ${feedback === 'TOO_HARD' ? 'btn-secondary pointer-events-none cursor-default' : 'btn-outline'}`}
                            >
                                <span className="">Too Hard</span>
                                <input
                                    type="radio"
                                    checked={feedback === 'Too Hard'}
                                    className="hidden"
                                    value={'TOO_HARD'}
                                    onChange={(e) => handleSelect(e)}
                                />
                            </label>
                        </form>
                    </div>
                    <Link
                        to={`/workout/${workout.id}`}
                        className="underline text-center text-sm font-bold text-gray-600"
                    >
                        Skip
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FinishedWorkout;
