import DesktopTopBar from 'components/DesktopTopBar';
import Hero from 'components/Hero';
import { useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

type ContextType = { prevPath: string | null; setPrevPath: (prevPath: string | null) => void };

const SignupLayout = () => {
    const [prevPath, setPrevPath] = useState<string | null>(null);

    return (
        <div className="flex h-dvh w-screen flex-col items-center justify-evenly bg-smoke-blue bg-cover bg-bottom">
            <div className='node-smoke-blue flex h-full w-full flex-col font-["SF-Pro-Display"] tracking-wider md:w-10/12 lg:w-7/12 xl:w-5/12'>
                <div className="hidden items-center lg:flex">
                    <DesktopTopBar />
                </div>
                <div className="flex items-center lg:hidden">
                    <Hero prevPath={prevPath} />
                </div>
                <div className="flex h-full w-full flex-col pt-16">
                    <Outlet context={{ prevPath, setPrevPath } satisfies ContextType} />
                </div>
            </div>
        </div>
    );
};

export function useReturnPath() {
    return useOutletContext<ContextType>();
}

export default SignupLayout;
