import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserScores } from './actions/leaderboardActions';

export interface UserScore {
    user_id: string;
    points: number;
    best_streak: number;
    workout_session_count: number;
    profile_image: string;
    total_workouts: number;
}

export interface LeaderboardState {
    userScore: UserScore;
    loading: boolean;
    error: string | null;
}

const initialState: LeaderboardState = {
    userScore: {
        user_id: '',
        points: 0,
        best_streak: 0,
        workout_session_count: 0,
        profile_image: '',
        total_workouts: 0
    },
    loading: false,
    error: null
};

const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserScores.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getUserScores.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.userScore = action.payload.results
                state.error = null
            })
            .addCase(getUserScores.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload.error;
            });
    }
});

export const { } = leaderboardSlice.actions;

export default leaderboardSlice.reducer;