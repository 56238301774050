import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPrices } from './actions/stripeActions';

export interface Plan {
    id: string;
    label: string;
    amount: number;
    type: string;
}

export interface StripeState {
    plans: Plan[];
    loading: boolean;
    error: string | null;
}

const initialState: StripeState = {
    plans: [],
    loading: false,
    error: null
};

const stripeSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPrices.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getPrices.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.plans = action.payload.results
                state.error = null
            })
            .addCase(getPrices.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload.error;
            });
    }
});

export const { } = stripeSlice.actions;

export default stripeSlice.reducer;
