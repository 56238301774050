import { AuthProvider } from 'components/AuthProvider';
import ProtectedRoute from 'components/ProtectedRoute';
import logging from 'config/logging';
import ConfirmCodePage from 'pages/auth/change-password';
import ForgotPasswordPage from 'pages/auth/forgot-password';
import AuthLayout from 'pages/auth/layout';
import Login from 'pages/auth/login';
import SignIn from 'pages/auth/signin';
import SignupPage from 'pages/auth/signup';
import Home from 'pages/home';
import HomeLayout from 'pages/layout';
import LibraryPage from 'pages/library/library';
import ProfilePage from 'pages/profile/profile';
import ProgramPage from 'pages/program/program';
import CheckoutPage from 'pages/signup/checkout';
import ChoosePlanPage from 'pages/signup/choose-plan';
import IntroductionPage from 'pages/signup/introduction';
import SignupLayout from 'pages/signup/layout';
import SetActivityPage from 'pages/signup/set-activity';
import SetGoalPage from 'pages/signup/set-goal';
import SetPreferencePage from 'pages/signup/set-preference';
import SetProfilePage from 'pages/signup/set-profile';
import SetProfilePicturePage from 'pages/signup/set-profile-picture';
import SetPurposeePage from 'pages/signup/set-purposee';
import WeightSelection from 'pages/signup/weight-selection';
import WelcomePage from 'pages/signup/welcome';
import TrainerPage from 'pages/trainer/trainer';
import TrainerVideoPage from 'pages/trainer/video';
import LogWeightPage from 'pages/workout/log-weight';
import WorkoutVideoPage from 'pages/workout/video';
import WorkoutPage from 'pages/workout/workout';
import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { setupAxiosInterceptors } from 'util/axios';
import { logout, setTokens } from './redux/authSlice';
import { store } from './redux/store';

const getAccessToken = () => store.getState().auth.access_token;
const getRefreshToken = () => store.getState().auth.refresh_token;

const onTokensRefreshed = (accessToken: string, refreshToken: string) => {
    store.dispatch(setTokens({ accessToken, refreshToken }));
};

const onError = (error: any) => {
    store.dispatch(logout());
};

setupAxiosInterceptors(getAccessToken, getRefreshToken, onTokensRefreshed, onError);

const Application: React.FunctionComponent<{}> = (props) => {
    useEffect(() => {
        logging.info('Loading application.');
    }, []);

    const router = createBrowserRouter([
        {
            path: '/',
            element: (
                <ProtectedRoute>
                    <HomeLayout />
                </ProtectedRoute>
            ),
            children: [
                {
                    path: '/',
                    element: <Home />,
                },
                {
                    path: '/library/:tab',
                    element: <LibraryPage />,
                },
                {
                    path: '/workout',
                    element: <Home />,
                },
                {
                    path: '/workout/:id',
                    element: <WorkoutPage />,
                },
                {
                    path: '/program/:id',
                    element: <ProgramPage />,
                },
                {
                    path: '/trainer/:id',
                    element: <TrainerPage />,
                },
                {
                    path: '/daily-workout',
                    element: <Home />,
                },
                {
                    path: '/profile',
                    element: <ProfilePage />,
                },
                {
                    path: '/settings',
                    element: <Home />,
                },
            ],
        },
        {
            path: '/workout/:id/video/:index',
            element: (
                <ProtectedRoute>
                    <WorkoutVideoPage />
                </ProtectedRoute>
            ),
        },
        {
            path: '/workout/:id/video/log-weight',
            element: (
                <ProtectedRoute>
                    <LogWeightPage />
                </ProtectedRoute>
            ),
        },
        {
            path: '/auth',
            element: <AuthLayout />,
            children: [
                {
                    path: '/auth',
                    element: <Login />,
                },
                {
                    path: '/auth/signin',
                    element: <SignIn />,
                },
                {
                    path: '/auth/signup',
                    element: <SignupPage />,
                },
                {
                    path: '/auth/forgot-password',
                    element: <ForgotPasswordPage />,
                },
                {
                    path: '/auth/change-password',
                    element: <ConfirmCodePage />,
                },
            ],
        },
        {
            path: '/signup',
            element: <SignupLayout />,
            children: [
                {
                    path: '/signup/set-profile',
                    element: <SetProfilePage />,
                },
                {
                    path: '/signup/set-goal',
                    element: <SetGoalPage />,
                },
                {
                    path: '/signup/set-preference',
                    element: <SetPreferencePage />,
                },
                {
                    path: '/signup/set-activity',
                    element: <SetActivityPage />,
                },
                {
                    path: '/signup/set-purposee',
                    element: <SetPurposeePage />,
                },
                {
                    path: '/signup/introduction',
                    element: <IntroductionPage />,
                },
                {
                    path: '/signup/weight-selection',
                    element: <WeightSelection />,
                },
                {
                    path: '/signup/welcome',
                    element: <WelcomePage />,
                },
                {
                    path: '/signup/choose-plan',
                    element: <ChoosePlanPage />,
                },
                {
                    path: '/signup/checkout',
                    element: <CheckoutPage />,
                },
                {
                    path: '/signup/set-profile-picture',
                    element: <SetProfilePicturePage />,
                },
            ],
        },
        {
            path: '/video/:id',
            element: (
                <ProtectedRoute>
                    <TrainerVideoPage />
                </ProtectedRoute>
            ),
        },
    ]);
    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    );
};

export default Application;
