import { yupResolver } from '@hookform/resolvers/yup';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import * as Yup from 'yup';
import { setNewUser } from '../../redux/authSlice';

interface IFormInput {
    gender: string;
    date_of_birth: string;
    height: number;
    weight: number;
}

const SetProfilePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { setPrevPath } = useReturnPath();
    const newUser = useSelector((state: RootState) => state.auth.newUser);

    useEffect(() => {
        setPrevPath(null);
    });

    const schema = Yup.object().shape({
        gender: Yup.string().nullable(),
        dateOfBirth: Yup.date().nullable().typeError('Invalid date'),
        height: Yup.number().nullable().typeError('Invalid height'),
        weight: Yup.number().nullable().typeError('Invalid weight'),
    });

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            gender: newUser?.gender ? newUser.gender : null,
            date_of_birth: newUser?.date_of_birth ? newUser?.date_of_birth : new Date().toISOString().split('T')[0],
            height: newUser?.height ? parseInt(newUser?.height) : null,
            weight: newUser.weight ? parseInt(newUser?.weight) : null,
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit = useCallback(
        async (data) => {
            console.log(data);
            dispatch(setNewUser(data));
            navigate('/signup/set-goal');
        },
        [dispatch, navigate]
    );

    const handleGenderSelect = useCallback(
        (gender: string) => {
            setValue('gender', gender);
        },
        [setValue]
    );

    const handleChangeDateOfBirth = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            setValue('date_of_birth', event.target.value);
        },
        [setValue]
    );

    return (
        <div className="flex h-full flex-col items-center justify-between">
            <p className="text-center font-bold text-white lg:text-2xl">Let's personalize Your Profile</p>
            <div className="relative flex w-full flex-col items-center">
                <div className="flex w-10/12 items-center justify-between">
                    <button
                        className={`btn w-5/12 rounded-full ${watch('gender') === 'male' ? 'btn-secondary' : 'btn-outline'}`}
                        onClick={() => handleGenderSelect('male')}
                        {...register('gender')}
                    >
                        Male
                    </button>
                    <button
                        className={`btn w-5/12 rounded-full ${watch('gender') === 'female' ? 'btn-secondary' : 'btn-outline'}`}
                        onClick={() => handleGenderSelect('female')}
                        {...register('gender')}
                    >
                        Female
                    </button>
                    <input type="hidden" {...register('gender')} />
                </div>
                {errors.gender && (
                    <div className="absolute -top-8 text-sm font-bold text-error opacity-50">
                        {errors.gender?.message}
                    </div>
                )}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex w-10/12 flex-col items-center space-y-2">
                <div className="flex w-full justify-center">
                    <div className="flex w-full justify-center rounded-full focus:outline-secondary">
                        <input
                            type="date"
                            placeholder="Date of Birth"
                            className="input input-bordered w-full rounded-full text-center"
                            onChange={handleChangeDateOfBirth}
                            {...register('date_of_birth')}
                        />
                        <div className="absolute -top-10 left-0 p-4 text-sm font-bold text-error opacity-50">
                            {errors.date_of_birth && errors.date_of_birth?.message}
                        </div>
                    </div>
                </div>
                <div className="relative w-full">
                    <input
                        type="number"
                        name={'height'}
                        placeholder="Height"
                        className="input input-bordered flex w-full justify-between rounded-full text-neutral focus:outline-secondary"
                        {...register('height')}
                    />
                    <span className="absolute right-2 top-1/2 -translate-y-1/2 transform p-4">fts</span>
                    {errors.height && (
                        <div className="absolute left-1/3 top-0 p-4 text-sm font-bold text-error opacity-50">
                            {errors.height?.message}
                        </div>
                    )}
                </div>
                <div className="relative w-full">
                    <input
                        type="number"
                        name={'weight'}
                        placeholder="Weight"
                        className="input input-bordered flex w-full justify-between rounded-full text-neutral focus:outline-secondary"
                        {...register('weight')}
                    />
                    <span className="absolute right-2 top-1/2 -translate-y-1/2 transform p-4">lbs</span>
                    {errors.weight && (
                        <div className="absolute left-1/3 top-0 p-4 text-sm font-bold text-error opacity-50">
                            {errors.weight?.message}
                        </div>
                    )}{' '}
                </div>
            </form>
            <div className="flex w-10/12 flex-col justify-center">
                <div className="flex justify-between">
                    <Link to={'/auth/signup'} className="btn btn-outline rounded-full px-8 hover:btn-secondary">
                        {'< Back'}
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-outline rounded-full px-8 hover:btn-secondary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {'Next >'}
                    </button>
                </div>
                <Link to="/signup/introduction" className="p-4 text-center text-sm font-normal text-gray-600 underline">
                    skip
                </Link>
                <FooterPhrase text="We use this information to build better routines for you" step={1} />
            </div>
        </div>
    );
};

export default SetProfilePage;
