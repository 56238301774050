type PropsType = {
    className?: string;
};

const Heart = ({ className }: PropsType) => {
    return (
        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.1331 4.0984L14 5.60641L14.867 4.0984C15.9692 2.18114 17.8818 1 20.0227 1C23.3523 1 26.25 4.0543 26.25 8C26.25 9.72825 25.5306 11.5348 24.346 13.3227C23.1664 15.1033 21.5752 16.7903 19.9537 18.2588C18.3362 19.7237 16.7143 20.9482 15.4944 21.8075C14.8854 22.2364 14.3789 22.5728 14.0262 22.801C14.0181 22.8063 14.01 22.8115 14.002 22.8166C13.9936 22.8112 13.9852 22.8056 13.9766 22.8C13.6237 22.5701 13.117 22.2314 12.5079 21.7997C11.2877 20.9351 9.66529 19.7041 8.04731 18.2347C6.42531 16.7617 4.83355 15.0721 3.65355 13.2939C2.46822 11.5077 1.75001 9.71006 1.75001 8C1.75001 4.0543 4.64771 1 7.97728 1C10.1182 1 12.0308 2.18114 13.1331 4.0984Z"
                stroke="#05BEF8"
                strokeWidth="2"
            />
        </svg>
    );
};

export default Heart;
