
const isLocalhost =
  window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
    ? true
    : false;

const isProd =
  window.location.hostname === "app.sweatsonic.com" ? true : false;

const config = {
  defaults: {
    namespace: "Application",
    api_url: isLocalhost
      ? "http://localhost:4000"
      : isProd
        ? "https://api.sweatsonic.com"
        : "https://api-dev.sweatsonic.com",
  },

  stripe_api_key: isProd
    ? "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"
    : "pk_test_51IEPFoKg2zMGhaBRelsrjcCAzpwiLn3RyLb4HIK7s0gLA0IzJKUowHLrqI0AqyqZWcESSU697CWAGIkaph6WV2RN00kZYzjRd0",

  stripe_product_id: isProd
    ? "prod_Kbb2mZqML7eMaE"
    : "prod_JApGhX3GCJte0a",

  oauth_google_client_id: isProd ? "204040026485-pcfpng0hdudo1c2j6e322mh134ducvf3.apps.googleusercontent.com" : "204040026485-pcfpng0hdudo1c2j6e322mh134ducvf3.apps.googleusercontent.com",
  oauth_apple_client_id: "com.sweatsonic.SweatSonic",
};

export default config;
