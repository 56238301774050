import WeightSelectionImage from 'assets/img/signup/weight.png';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const WeightSelection = () => {
    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath('/signup/introduction');
    });
    return (
        <div className="h-full flex flex-col justify-between items-center w-full text-white font-semibold">
            <div className="h-1/4 flex items-center justify-center">
                <img src={WeightSelectionImage} className="w-3/4" alt="weight" />
            </div>
            <span className="text-white font-bold text-2xl">Weight Selection</span>
            <div className="flex flex-col justify-center items-center font-normal text-sm w-2/3">
                <p className="text-center">
                    <span className="text-secondary">Use Weights</span> That Challenge you But That{' '}
                    <span className="text-secondary">You Can Control</span> With Good Form.
                </p>
                <p className="text-center">
                    <span className="text-secondary">Reduce Weight</span> When Necessary and{' '}
                    <span className="text-secondary">Keep Going Until</span> Each Song is Complete.
                </p>
            </div>
            <Link to={'/signup/welcome'} className="btn btn-outline btn-wide rounded-full">
                Continue
            </Link>
            <FooterPhrase text={'Get Ready For Progress'} step={7} />
        </div>
    );
};

export default WeightSelection;
