import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const PlayCircle = ({ className }: PropsType) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn('', className)}
        >
            <g filter="url(#filter0_d_2_72811)">
                <path
                    d="M5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11Z"
                    stroke="#05BEF8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.66667 13V9L13 11L9.66667 13Z"
                    stroke="#05BEF8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2_72811"
                    x="-1"
                    y="-1"
                    width="24"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0196078 0 0 0 0 0.745098 0 0 0 0 0.972549 0 0 0 1 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_72811" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_72811" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default PlayCircle;
