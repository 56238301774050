import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const Followers = ({ className }: PropsType) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn('', className)}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00039 8C6.35572 8 5.01172 6.65667 5.01172 5.01133C5.01172 3.366 6.35572 2 8.00039 2C9.64505 2 10.9891 3.34333 10.9891 4.98867C10.9891 6.634 9.64505 8 8.00039 8Z"
                stroke="#05BEF8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.668 14H3.33464C2.96797 14 2.66797 13.7 2.66797 13.3333V12.6667C2.66797 11.2 3.86797 10 5.33464 10H10.668C12.1346 10 13.3346 11.2 13.3346 12.6667V13.3333C13.3346 13.7 13.0346 14 12.668 14Z"
                stroke="#05BEF8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Followers;
