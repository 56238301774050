import CloseIcon from 'assets/img/icons/CloseIcon';
import VideoControls from 'components/VideoControls';
import VideoPlayer from 'components/VideoPlayer';
import Hls from 'hls.js';
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface VideoProps {
    stream_url: string;
    video_url: string;
    trainer_name: string;
    title: string;
    thumbnail_image: string;
}

const Video = (props: {
    videos: VideoProps[];
    videoIndex: number;
    backPath: string;
    autoPlay?: boolean;
    handleFinishSession: () => void;
    handleFinishVideo: (video: VideoProps, watchedTime: number) => void;
    handleVideoTick: () => void;
}) => {
    const navigate = useNavigate();
    const { videos, backPath, videoIndex, autoPlay, handleFinishSession, handleFinishVideo, handleVideoTick } = props;
    const videoElementsRef = useRef<React.RefObject<HTMLVideoElement>[]>(
        videos.map(() => createRef<HTMLVideoElement>())
    );
    const hlsRef = useRef<Hls | null>();
    const prevHlsRef = useRef<Hls | null>();
    const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(videoIndex);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [play, setPlay] = useState<boolean>(autoPlay);
    const [showControls, setShowControls] = useState<boolean>(false);
    const [controlsCountDown, setControlsCountDown] = useState<number>(5);
    const [progress, setProgress] = useState<number>(0);

    const handleCloseVideo = useCallback(() => {
        navigate(backPath);
    }, [backPath, navigate]);

    const handleVideoEnded = useCallback(() => {
        if (currentVideoIndex === videos.length - 1) {
            handleFinishSession();
        } else {
            setCurrentVideoIndex((prev) => prev + 1);
        }
    }, [currentVideoIndex, handleFinishSession, videos.length]);

    const handleTimeUpdate = useCallback(() => {
        const progress =
            (videoElementsRef.current[currentVideoIndex].current.currentTime /
                videoElementsRef.current[currentVideoIndex].current.duration) *
            100;
        setProgress(progress);
        setCurrentTime(videoElementsRef.current[currentVideoIndex].current.currentTime);

        if (Math.floor(videoElementsRef.current[currentVideoIndex].current.currentTime) % 10 === 0) {
            handleVideoTick();
        }

        const nextVideoIndex = currentVideoIndex + 1;
        if (
            nextVideoIndex < videos.length &&
            progress > 80 &&
            !videoElementsRef.current[nextVideoIndex].current.readyState
        ) {
            const nextVideo = videos[nextVideoIndex];
            const nextHls = new Hls();
            nextHls.loadSource(nextVideo.stream_url);
            nextHls.attachMedia(videoElementsRef.current[nextVideoIndex].current);
            prevHlsRef.current = hlsRef.current;
            hlsRef.current = nextHls;
        }
    }, [currentVideoIndex, handleVideoTick, videos]);

    // useEffect(() => {
    //     const newPath = `${window.location.pathname.split('/').slice(0, -1).join('/')}/${currentVideoIndex}`;
    //     window.history.replaceState(null, '', newPath);
    // }, [currentVideoIndex, videos]);

    useEffect(() => {
        const videoElement = videoElementsRef.current[currentVideoIndex].current;
        prevHlsRef.current?.destroy();

        if (Hls.isSupported() && !videoElement.readyState && videos[currentVideoIndex].stream_url) {
            const hls = new Hls();
            hls.loadSource(videos[currentVideoIndex].stream_url);
            hls.attachMedia(videoElement);
            hlsRef.current = hls;
        } else {
            videoElement.src = videos[currentVideoIndex].video_url;
        }

        videoElement.addEventListener('timeupdate', handleTimeUpdate);
        videoElement.addEventListener('ended', handleVideoEnded);

        return () => {
            handleFinishVideo(videos[currentVideoIndex], Math.floor(videoElement.currentTime));
            !videoElement.paused && videoElement.pause();
            videoElement.currentTime = 0;
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            videoElement.removeEventListener('ended', handleVideoEnded);
        };
    }, [currentVideoIndex, handleFinishVideo, handleTimeUpdate, handleVideoEnded, videos]);

    useEffect(() => {
        if (play && videoElementsRef.current[currentVideoIndex].current.paused) {
            videoElementsRef.current[currentVideoIndex].current.play();
        } else {
            videoElementsRef.current[currentVideoIndex].current.pause();
        }
    }, [currentVideoIndex, play]);

    useEffect(() => {
        let intervalId: number;
        if (controlsCountDown > 0) {
            intervalId = window.setInterval(() => {
                setControlsCountDown((prev) => prev - 0.01);
            }, 10);
        }
        if (controlsCountDown < 0) {
            setShowControls(false);
        }
        return () => clearInterval(intervalId);
    }, [controlsCountDown]);

    const handleBackward = useCallback(() => {
        videoElementsRef.current[currentVideoIndex].current.currentTime = Math.max(
            0,
            videoElementsRef.current[currentVideoIndex].current.currentTime - 10 < 0
                ? 0
                : videoElementsRef.current[currentVideoIndex].current.currentTime - 10
        );
    }, [currentVideoIndex]);

    const handleForward = useCallback(() => {
        videoElementsRef.current[currentVideoIndex].current.currentTime = Math.max(
            10,
            videoElementsRef.current[currentVideoIndex].current.currentTime + 10
        );
    }, [currentVideoIndex]);

    const handleLastVideo = useCallback(() => {
        if (currentVideoIndex >= 0) {
            prevHlsRef.current = hlsRef.current;
            setCurrentVideoIndex((prev) => prev - 1);
        }
    }, [currentVideoIndex]);

    const handleNextVideo = useCallback(() => {
        if (currentVideoIndex < videos.length) {
            prevHlsRef.current = hlsRef.current;
            setCurrentVideoIndex((prev) => prev + 1);
        }
    }, [currentVideoIndex, videos.length]);

    const handleSelectVideo = useCallback((index) => {
        prevHlsRef.current = hlsRef.current;
        setCurrentVideoIndex(index);
    }, []);

    const handleFullScreen = useCallback(() => {
        const videoElement = videoElementsRef.current[currentVideoIndex].current;
        if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
        }
    }, [currentVideoIndex]);

    useEffect(() => {
        setCurrentTime(videoElementsRef.current[currentVideoIndex].current?.currentTime);
    }, [currentVideoIndex]);

    const videoControlsProps = useMemo(
        () => ({
            videos,
            showControls,
            setShowControls,
            controlsCountDown,
            currentVideoIndex,
            progress,
            currentTime,
            play,
            setPlay,
            handleBackward,
            handleForward,
            handleLastVideo,
            handleNextVideo,
            handleSelectVideo,
            handleFullScreen,
        }),
        [
            videos,
            showControls,
            controlsCountDown,
            currentVideoIndex,
            progress,
            currentTime,
            play,
            handleBackward,
            handleForward,
            handleLastVideo,
            handleNextVideo,
            handleSelectVideo,
            handleFullScreen,
        ]
    );
    return (
        <div className='h-dvh font-["Poppins-Med"]'>
            {showControls && (
                <div className="absolute top-0 left-0 w-full p-8 z-30 flex">
                    <div className="flex flex-col items-start w-full">
                        <p className="text-white capitalize font-semibold">{videos[currentVideoIndex]?.trainer_name}</p>
                        <p className="text-white">{videos[currentVideoIndex]?.title}</p>
                    </div>
                    <div className="absolute top-0 right-0 m-8 z-20 cursor-pointer" onClick={handleCloseVideo}>
                        <CloseIcon fillColor={'none'} />
                    </div>
                </div>
            )}
            <VideoControls {...videoControlsProps} />
            {videoElementsRef.current.map((video, index) => (
                <VideoPlayer
                    videoRef={video}
                    currentVideoIndex={currentVideoIndex}
                    videoIndex={index}
                    showControls={showControls}
                    setShowControls={setShowControls}
                    setControlsCountDown={setControlsCountDown}
                />
            ))}
        </div>
    );
};

export default Video;
