const Filter = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 16H19M1 16H3M3 16C3 17.3807 4.11929 18.5 5.5 18.5C6.88071 18.5 8 17.3807 8 16C8 14.6193 6.88071 13.5 5.5 13.5C4.11929 13.5 3 14.6193 3 16ZM18 10H19M1 10H8M11 4H19M11 4C11 2.61929 9.88071 1.5 8.5 1.5C7.11929 1.5 6 2.61929 6 4C6 5.38071 7.11929 6.5 8.5 6.5C9.88071 6.5 11 5.38071 11 4ZM1 4H2M14.5 12.5C13.1193 12.5 12 11.3807 12 10C12 8.61929 13.1193 7.5 14.5 7.5C15.8807 7.5 17 8.61929 17 10C17 11.3807 15.8807 12.5 14.5 12.5Z"
                stroke="#05BEF8"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Filter;
