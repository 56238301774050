type PropsType = {
    fill?: string;
};

const Fire = ({ fill = 'none' }: PropsType) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5037 6.96809C9.5037 9.00149 7.91313 10.6499 5.95107 10.6499C3.98901 10.6499 2.39844 9.00149 2.39844 6.96809C2.39844 6.2264 2.61667 4.98452 3.28659 4.3499L4.4946 5.98205C4.53294 6.03386 4.61742 6.00749 4.6179 5.94305C4.62357 5.15711 4.73088 2.36464 5.95107 1.6499C5.95107 1.6499 9.5037 4.93466 9.5037 6.96809Z"
                stroke="#05BEF8"
                strokeWidth="0.8"
                strokeLinejoin="round"
            />
            <path
                d="M5.8494 9.06741C5.58807 9.06741 5.32929 9.01593 5.08785 8.91594C4.84641 8.81592 4.62705 8.66934 4.44224 8.48454C4.25744 8.29977 4.11087 8.08038 4.01085 7.83894C3.91086 7.5975 3.85938 7.33872 3.85938 7.07739"
                stroke="#05BEF8"
                strokeWidth="0.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Fire;
