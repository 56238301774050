import { yupResolver } from '@hookform/resolvers/yup';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import * as Yup from 'yup';
import { setNewUser } from '../../redux/authSlice';

interface IFormInput {
    workout_length: string | null;
}

const SetPreferencePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const newUser = useSelector((state: RootState) => state.auth.newUser);
    const { setPrevPath } = useReturnPath();

    useEffect(() => {
        setPrevPath(null);
    });

    const schema = Yup.object().shape({
        preference: Yup.string().nullable(),
    });

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        defaultValues: {
            workout_length: newUser.workout_length ? newUser.workout_length : null,
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit = useCallback(
        async (value) => {
            dispatch(setNewUser(value));
            navigate('/signup/set-activity');
        },
        [dispatch, navigate]
    );

    return (
        <React.Fragment>
            <form className="flex flex-col justify-evenly items-center h-full w-full">
                <p className="text-label text-2xl font-bold text-white text-center">Workout Preference?</p>
                {errors.workout_length && <p className="text-error text-center">{errors.workout_length.message}</p>}
                <div className="flex flex-col justify-around space-y-6 w-10/12">
                    <label
                        className={`btn hover:btn-secondary rounded-full ${watch('workout_length') && watch('workout_length').includes('Less Than 10 Minutes') ? 'btn-secondary' : 'btn-outline'}`}
                    >
                        <span className="">Less Than 10 Minutes</span>
                        <input
                            type="radio"
                            checked={
                                watch('workout_length') && watch('workout_length').includes('Less Than 10 Minutes')
                            }
                            className="hidden"
                            value={'Less Than 10 Minutes'}
                            {...register('workout_length')}
                        />
                    </label>
                    <label
                        className={`btn hover:btn-secondary rounded-full ${watch('workout_length') && watch('workout_length').includes('Less Than 20 Minutes') ? 'btn-secondary' : 'btn-outline'}`}
                    >
                        <span className="">Less Than 20 Minutes</span>
                        <input
                            type="radio"
                            checked={
                                watch('workout_length') && watch('workout_length').includes('Less Than 20 Minutes')
                            }
                            className="hidden"
                            value={'Less Than 20 Minutes'}
                            {...register('workout_length')}
                        />
                    </label>
                    <label
                        className={`btn hover:btn-secondary rounded-full ${watch('workout_length') && watch('workout_length').includes('Less Than 40 Minutes') ? 'btn-secondary' : 'btn-outline'}`}
                    >
                        <span className="">Less Than 40 Minutes</span>
                        <input
                            type="radio"
                            checked={
                                watch('workout_length') && watch('workout_length').includes('Less Than 40 Minutes')
                            }
                            className="hidden"
                            value={'Less Than 40 Minutes'}
                            {...register('workout_length')}
                        />
                    </label>
                    <label
                        className={`btn hover:btn-secondary rounded-full ${watch('workout_length') && watch('workout_length').includes('More Than 45 Minutes') ? 'btn-secondary' : 'btn-outline'}`}
                    >
                        <span className="">More Than 45 Minutes</span>
                        <input
                            type="radio"
                            checked={
                                watch('workout_length') && watch('workout_length').includes('More Than 45 Minutes')
                            }
                            className="hidden"
                            value={'More Than 45 Minutes'}
                            {...register('workout_length')}
                        />
                    </label>
                    <div className="flex justify-between">
                        <button
                            onClick={() => navigate('/signup/set-goal')}
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                        >
                            {'< Back'}
                        </button>
                        <button
                            type="submit"
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {'Next >'}
                        </button>
                    </div>
                </div>
                <Link to="/signup/introduction" className="underline text-center text-sm font-normal text-gray-600">
                    skip
                </Link>
            </form>
            <FooterPhrase text={'We use this information to build better routines for you'} step={3} />
        </React.Fragment>
    );
};

export default SetPreferencePage;
