type PropsType = {
    fill?: string;
};

const Home = ({ fill = 'none' }: PropsType) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.5402 6.82006L14.7802 2.79006C13.2102 1.69006 10.8002 1.75006 9.29023 2.92006L4.28023 6.83006C3.28023 7.61006 2.49023 9.21006 2.49023 10.4701V17.3701C2.49023 19.9201 4.56023 22.0001 7.11023 22.0001H17.8902C20.4402 22.0001 22.5102 19.9301 22.5102 17.3801V10.6001C22.5102 9.25006 21.6402 7.59006 20.5402 6.82006ZM13.2502 18.0001C13.2502 18.4101 12.9102 18.7501 12.5002 18.7501C12.0902 18.7501 11.7502 18.4101 11.7502 18.0001V15.0001C11.7502 14.5901 12.0902 14.2501 12.5002 14.2501C12.9102 14.2501 13.2502 14.5901 13.2502 15.0001V18.0001Z"
                fill="current"
            />
        </svg>
    );
};

export default Home;
