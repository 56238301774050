import FinishedWorkout from 'components/FinishedWorkout';
import Video from 'components/Video';
import VideoCountDown from 'components/VideoCountDown';
import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../redux/store';
import { addWatchedVideo } from '../../redux/workoutSlice';

const WorkoutVideoPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { index, id } = useParams();
    const { workout, loading } = useSelector((state: RootState) => state.workouts, shallowEqual);
    const [finished, setFinished] = useState(false);
    const [showCountDown, setShowCountDown] = useState<boolean>(true);
    const [videos, setVideos] = useState([]);

    const handleCloseCountDown = useCallback(() => {
        setShowCountDown(false);
    }, []);

    const handleFinishVideo = useCallback(
        (video, watchedTime) => {
            dispatch(addWatchedVideo({ video, watchedTime }));
        },
        [dispatch]
    );

    const handleVideoTick = useCallback(() => {}, []);

    const handleFinishSession = useCallback(() => {
        setFinished(true);
    }, []);

    useEffect(() => {
        setVideos(
            workout.videos.map((video) => ({
                stream_url: video.workout_stream_url,
                video_url: video.video_url,
                trainer_name: video.trainer_name,
                title: video.title,
                thumbnail_image: video.video_thumbnail_image,
            }))
        );
    }, [workout.videos]);

    return finished ? (
        <FinishedWorkout />
    ) : loading ? (
        <div>loading</div>
    ) : (
        <div className="w-full h-dvh">
            <VideoCountDown
                workout={workout}
                showPreStart={showCountDown}
                handleCloseCountDown={handleCloseCountDown}
            />
            {videos.length > 0 && (
                <Video
                    videos={videos}
                    videoIndex={parseInt(index)}
                    backPath={`/workout/${id}`}
                    autoPlay={true}
                    handleFinishSession={handleFinishSession}
                    handleFinishVideo={handleFinishVideo}
                    handleVideoTick={handleVideoTick}
                />
            )}
        </div>
    );
};

export default WorkoutVideoPage;
