import { createContext, ReactNode, useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export interface AuthContextProps {
    access_token: string;
    email_verified: boolean;
    refresh_token: string;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const auth = useSelector((state: RootState) => state.auth);
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used insided an AuthProvider');
    }
    return context;
};
