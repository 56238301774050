import Close from 'icons/close';
import Filter from 'icons/filter';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slider';
import { AppDispatch, RootState } from 'redux/store';
import { getAllVideos } from '../../redux/actions/videoActions';

const InstructionsLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allVideos, loading } = useSelector((state: RootState) => state.videos);
    const [filters, setFilters] = useState();
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [modalOpen, setModalOpen] = useState(false);
    const [minDuration, setMinDuration] = useState(null);
    const [maxDuration, setMaxDuration] = useState(null);
    const [bodyPart, setBodyPart] = useState([]);

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/exercise/' + element.id);
        },
        [navigate]
    );

    const handleOpenFilter = useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setModalOpen(false);
    }, []);

    useEffect(() => {
        dispatch(getAllVideos({ page, take, search: debouncedSearch }));
    }, [debouncedSearch, dispatch, page, take]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    return (
        <div className="mb-16 w-full overflow-y-scroll md:h-5/6 lg:mb-0">
            <div className="z-20 flex h-1/6 items-center justify-center bg-black p-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input input-bordered w-full rounded-full border p-2"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-2 top-3">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
                <button
                    onClick={handleOpenFilter}
                    className="shadow-glow ml-2 flex items-center rounded-full border-secondary bg-primary px-4 py-2 text-secondary"
                >
                    <Filter />
                    <span className="ml-2">Filter</span>
                </button>
                <div className={`modal ${modalOpen ? 'modal-open' : ''}`}>
                    <div className="modal-box border border-secondary">
                        <button onClick={() => setModalOpen(false)} className="absolute right-2 top-2 p-2">
                            <Close />
                        </button>
                        <h3 className="text-center text-lg font-bold">Filter Programs</h3>
                        <div className="mt-4">
                            <label className="flex w-full justify-between">
                                <span>Duration Range</span>
                                <span>
                                    {minDuration} - {maxDuration}
                                </span>
                            </label>
                            <Slider
                                className={'flex h-6 items-center'}
                                thumbClassName="bg-white w-6 h-6 rounded-full border-2 border-secondary"
                                defaultValue={[0, 500]}
                                value={[minDuration, maxDuration]}
                                onChange={(value) => {
                                    setMinDuration(value[0]);
                                    setMaxDuration(value[1]);
                                }}
                                renderThumb={({ key, ...props }, state) => <div key={key} {...props}></div>}
                                minDistance={10}
                                renderTrack={({ key, ...props }, state: { index }) => (
                                    <div
                                        key={key}
                                        {...props}
                                        className={`h-1 rounded-full ${state.index === 1 ? 'bg-secondary' : 'bg-neutral-content'}`}
                                    />
                                )}
                                min={0}
                                max={500}
                                step={10}
                            />
                        </div>
                        <div className="modal-action">
                            <button onClick={handleCloseFilter} className="btn btn-outline btn-secondary">
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid h-5/6 w-full grid-cols-1 justify-items-center gap-4 space-y-4 overflow-scroll p-2 pb-16 lg:grid-cols-2">
                {loading ? (
                    <div className="skeleton"></div>
                ) : (
                    allVideos?.map((video, index) => (
                        <div
                            onClick={() => handleSelectElement(video)}
                            id={index.toString()}
                            key={index}
                            className={`flex w-full cursor-pointer justify-center rounded-lg`}
                        >
                            {video.title}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default InstructionsLibrary;
