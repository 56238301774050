import logotype from 'assets/img/logotype.png';
import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const Logotype = ({ className }: PropsType) => {
    return <img className={cn('', className)} src={logotype} alt="close" />;
};

export default Logotype;
