import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const getUserScores = createAsyncThunk(
    'leaderboard/me',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/leaderboard/me`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);
