import { Clock } from 'icons';
import Close from 'icons/close';
import Filter from 'icons/filter';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slider';
import { AppDispatch, RootState } from 'redux/store';
import { getAllWorkoutVideos } from '../../redux/actions/workoutActions';
import { secondsToMinutes } from '../../util/time';

const ExercisesLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allWorkoutVideos, loading } = useSelector((state: RootState) => state.workouts);
    const [filters, setFilters] = useState();
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [modalOpen, setModalOpen] = useState(false);
    const [minDuration, setMinDuration] = useState(null);
    const [maxDuration, setMaxDuration] = useState(null);
    const [bodyPart, setBodyPart] = useState([]);

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/video/' + element.id);
        },
        [navigate]
    );

    const handleOpenFilter = useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setModalOpen(false);
    }, []);

    useEffect(() => {
        dispatch(
            getAllWorkoutVideos({
                page,
                take,
                search: debouncedSearch,
                min_duration: minDuration,
                max_duration: maxDuration,
                categories: bodyPart,
            })
        );
    }, [bodyPart, debouncedSearch, dispatch, maxDuration, minDuration, page, take]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    return (
        <div className="mb-16 w-full overflow-y-scroll md:h-5/6 lg:mb-0">
            <div className="z-20 flex h-1/6 items-center justify-center bg-black p-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input input-bordered w-full rounded-full border p-2"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-2 top-3">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
                <button
                    onClick={handleOpenFilter}
                    className="shadow-glow ml-2 flex items-center rounded-full border-secondary bg-primary px-4 py-2 text-secondary"
                >
                    <Filter />
                    <span className="ml-2">Filter</span>
                </button>
                <div className={`modal ${modalOpen ? 'modal-open' : ''}`}>
                    <div className="modal-box border border-secondary">
                        <button onClick={() => setModalOpen(false)} className="absolute right-2 top-2 p-2">
                            <Close />
                        </button>
                        <h3 className="text-center text-lg font-bold">Filter Programs</h3>
                        <div className="mt-4">
                            <label className="flex w-full justify-between">
                                <span>Duration Range</span>
                                <span>
                                    {minDuration} - {maxDuration}
                                </span>
                            </label>
                            <Slider
                                className={'flex h-6 items-center'}
                                thumbClassName="bg-white w-6 h-6 rounded-full border-2 border-secondary"
                                defaultValue={[0, 500]}
                                value={[minDuration, maxDuration]}
                                onChange={(value) => {
                                    setMinDuration(value[0]);
                                    setMaxDuration(value[1]);
                                }}
                                renderThumb={({ key, ...props }, state) => <div key={key} {...props}></div>}
                                minDistance={10}
                                renderTrack={({ key, ...props }, state: { index }) => (
                                    <div
                                        key={key}
                                        {...props}
                                        className={`h-1 rounded-full ${state.index === 1 ? 'bg-secondary' : 'bg-neutral-content'}`}
                                    />
                                )}
                                min={0}
                                max={500}
                                step={10}
                            />
                        </div>
                        <div className="modal-action">
                            <button onClick={handleCloseFilter} className="btn btn-outline btn-secondary">
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex h-5/6 w-full flex-col items-center space-y-4 overflow-y-scroll p-2 pb-16 lg:pb-0">
                <div className="flex w-full justify-between px-4 text-sm text-white lg:text-lg">
                    <span className="flex w-1/5 items-center justify-start lg:w-2/12">Exercise</span>
                    <div className="flex w-4/5 items-center justify-end lg:w-10/12">
                        <span className="mr-1">Duration</span>
                        <Clock />
                    </div>
                </div>
                {loading
                    ? [0, 1, 2, 3, 4].map((val) => (
                          <div key={val} className="flex h-full w-full p-4">
                              <div className="skeleton w-1/5 bg-secondary/20 lg:w-1/12"></div>
                              <div className="ml-2 flex h-full w-4/5 flex-col items-start justify-center lg:w-11/12">
                                  <div className="skeleton h-2 w-24 bg-secondary/20"></div>
                                  <div className="skeleton mt-2 h-2 w-6 bg-secondary/20"></div>
                              </div>
                          </div>
                      ))
                    : allWorkoutVideos.map((video, index) => (
                          <div
                              onClick={() => handleSelectElement(video)}
                              id={index.toString()}
                              key={index}
                              className={`flex w-full cursor-pointer rounded-lg`}
                          >
                              <img
                                  className="w-1/5 object-cover lg:w-1/12"
                                  src={video.video_thumbnail_image}
                                  alt="video thumbnail"
                              />
                              <div className="flex w-4/5 justify-between lg:w-11/12">
                                  <div className="flex flex-col items-start justify-center p-2">
                                      <span className="text-sm">{video.title}</span>
                                      <span className="text-xs">{video.trainer_name}</span>
                                  </div>
                                  <div className="w-1/5 content-center text-end text-xs">
                                      <span>{secondsToMinutes(video.video_duration)} MIN</span>
                                  </div>
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

export default ExercisesLibrary;
