import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { getPrices } from '../../redux/actions/stripeActions';
import { createBilling } from '../../redux/actions/userActions';

const ChoosePlanPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { plans } = useSelector((state: RootState) => state.stripe);
    const [selectedPlanId, setSelectedPlanId] = useState<string>();
    const { setPrevPath } = useReturnPath();

    useEffect(() => {
        setPrevPath('/signup/welcome');
    });

    useEffect(() => {
        dispatch(getPrices());
    }, [dispatch]);

    const { handleSubmit } = useForm();

    const selectPlan = useCallback((id: string) => {
        setSelectedPlanId(id);
    }, []);

    const onSubmit = useCallback(() => {
        if (selectedPlanId) {
            dispatch(createBilling({ priceId: selectedPlanId }));
            navigate('/signup/checkout');
        } else {
            navigate('/signup/set-profile-picture');
        }
    }, [dispatch, navigate, selectedPlanId]);

    return (
        <form className="h-full flex flex-col justify-evenly items-center w-full text-white font-semibold">
            <span className="text-white font-bold text-2xl">Welcome</span>
            <div className="flex flex-col justify-center items-center font-normal tracking-tighter w-11/12">
                <p className="text-center ">
                    For A Limited Time, If You Purchase An Annual Pass,
                    <span className="text-secondary">You Will Be Able To Gift 1 Extra Workout</span> Annual Pass
                </p>
                <p className="text-center text-secondary font-bold tracking-wider">
                    AVERAGE COST OF A PERSONAL TRAINER IS <span className="text-lg">$1,000</span> PER MONTH
                </p>
            </div>
            <div className="w-full flex flex-col items-center p-2 space-y-2">
                {plans.length === 0 && <span className="loading loading-dots loading-lg"></span>}
                {plans.map((p, i) => (
                    <div
                        onClick={() => selectPlan(p.id)}
                        className={`card bg-base-100 ${selectedPlanId === p.id ? 'border-secondary hover:border-white' : 'border-white hover:border-secondary'} border focus:border-secondary w-10/12 cursor-pointer hover:scale-105`}
                    >
                        <div className="flex-row card-body font-normal p-2">
                            <div className="h-full flex items-center">
                                <input type="checkbox" checked={selectedPlanId === p.id} className="rounded-full" />
                            </div>
                            <div className="flex flex-row justify-between w-full pl-2">
                                <div className="flex flex-col h-full">
                                    <h2 className="font-light">
                                        Commit for a <span className="font-bold">{p.type}</span>
                                    </h2>
                                    <p className="text-gray-600 text-xs">{p.label}</p>
                                </div>
                                <div className="flex flex-col h-full justify-center items-center">
                                    <span className="text-secondary">
                                        $<span className="text-2xl">{p.amount}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={handleSubmit(onSubmit)} type="submit" className="btn btn-outline btn-wide rounded-full">
                {selectedPlanId ? 'Continue' : 'Start Your Free Trial'}
            </button>
            <Link to="/" className="underline text-center text-sm font-normal text-gray-600">
                skip
            </Link>
            <FooterPhrase text={'Get Ready For Progress'} step={9} />
        </form>
    );
};

export default ChoosePlanPage;
