import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const getAllVideos = createAsyncThunk(
    'videos/all',
    async (data: { page: number, take: number, search?: string, exerciseType?: string, dropdown?: boolean, minDuration?: number, maxDuration?: number, exercise?: string, unpublished?: string, featured?: string }, { rejectWithValue }) => {
        try {
            const { page, take, search, dropdown, minDuration, maxDuration, exerciseType, exercise, featured, unpublished } = data
            const params = new URLSearchParams();
            params.append('page', page.toString());
            params.append('take', take.toString());
            if (search) params.append('search', search);
            if (dropdown) params.append('dropdown', dropdown.toString());
            if (minDuration) params.append('min_duration', minDuration.toString());
            if (maxDuration) params.append('max_duration', maxDuration.toString());
            if (exerciseType) params.append('exercise_type', exerciseType.toString());
            if (exercise) params.append('exercise', exercise.toString());
            if (featured) params.append('featured', featured.toString());
            if (unpublished) params.append('unpublished', unpublished.toString());
            const response = await connection.get(`/videos?${params.toString()}`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const getFeaturedVideos = createAsyncThunk(
    'videos/fetured',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get('/videos/featured');
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);

export const logWeight = createAsyncThunk(
    'videos/log-wieght',
    async (data: {
        duration_time: number, video_id: number, amount_weight_loss: number
    }[], { rejectWithValue }) => {
        try {
            const response = await connection.post('/videos/log/weight', data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch');
        }
    }
);
