import BottomNav from 'components/BottomNav';
import DesktopNav from 'components/DesktopNav';
import DesktopTopBar from 'components/DesktopTopBar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className='flex h-dvh w-full justify-center font-["SF-Pro-Display"]'>
            <DesktopTopBar />
            <DesktopNav />
            <div className="flex h-full w-full flex-col items-center justify-between lg:w-8/12">
                <Outlet />
                <BottomNav />
            </div>
        </div>
    );
};

export default Layout;
