type PropsType = {
    fill?: string;
};

const Profile = ({ fill = 'none' }: PropsType) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4996 12C10.0326 12 8.0166 9.985 8.0166 7.517C8.0166 5.049 10.0326 3 12.4996 3C14.9666 3 16.9826 5.015 16.9826 7.483C16.9826 9.951 14.9666 12 12.4996 12Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5 21H5.5C4.95 21 4.5 20.55 4.5 20V19C4.5 16.8 6.3 15 8.5 15H16.5C18.7 15 20.5 16.8 20.5 19V20C20.5 20.55 20.05 21 19.5 21Z"
                fill={fill}
            />
        </svg>
    );
};

export default Profile;
