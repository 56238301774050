import ProfilePicture from 'assets/img/signup/profile-picture.png';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { updateProfilePicture } from '../../redux/actions/userActions';

const SetProfilePicturePage = () => {
    const { setPrevPath } = useReturnPath();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { loading } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        setPrevPath('/signup/choose-plan');
    }, [setPrevPath]);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] || null;
        setSelectedFile(file);
    }, []);

    const handleSubmit = useCallback(() => {
        if (selectedFile) {
            dispatch(updateProfilePicture({ file: selectedFile }));
            navigate('/');
        } else {
            alert('Please select a file first.');
        }
    }, [dispatch, navigate, selectedFile]);

    return (
        <div className="h-full flex flex-col justify-evenly items-center w-full">
            <span className="text-white font-bold text-2xl">Profile Picture</span>
            <div className="flex flex-col justify-center items-center font-normal w-1/2 space-y-4">
                <div className="flex items-center justify-start">
                    {selectedFile ? (
                        <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Profile"
                            className="w-32 h-32 object-cover rounded-full"
                        />
                    ) : (
                        <img src={ProfilePicture} alt="Profile" className="w-32 h-32 object-cover rounded-full" />
                    )}
                </div>
                <input
                    type="file"
                    className="file-input file-input-bordered w-full max-w-xs"
                    accept="image/*"
                    onChange={handleFileChange}
                />
            </div>
            <div className="flex flex-col items-center">
                <p className="text-secondary">Make Sure Your Picture Is:</p>
                <p>
                    <span className="text-secondary">I.</span> less than 2MB
                </p>
                <p>
                    <span className="text-secondary">II.</span> has dimensions smaller than 1200x1200px
                </p>
            </div>
            <button onClick={handleSubmit} className={`btn btn-outline btn-wide rounded-full`} disabled={loading}>
                Save & Continue
            </button>
            <FooterPhrase text={'Get Ready For Progress'} step={11} />
        </div>
    );
};

export default SetProfilePicturePage;
