import { GoogleOAuthProvider } from '@react-oauth/google';
import ConfirmationNotification from 'components/ConfirmationNotification';
import WarningNotification from 'components/WarningNotification';
import config from 'config/config';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import Application from './application';
import './assets/css/global.scss';
import { persistor, store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Provider store={store}>
        <GoogleOAuthProvider clientId={config.oauth_google_client_id}>
            <PersistGate loading={null} persistor={persistor}>
                <ConfirmationNotification />
                <WarningNotification />
                <Application />
            </PersistGate>
        </GoogleOAuthProvider>
    </Provider>
);
