import { Clock, Fire } from 'icons';
import Close from 'icons/close';
import Filter from 'icons/filter';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slider';
import { AppDispatch, RootState } from 'redux/store';
import { getAllPrograms } from '../../redux/actions/programActions';

const ProgramsLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allPrograms, loading } = useSelector((state: RootState) => state.programs);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [modalOpen, setModalOpen] = useState(false);
    const [minDuration, setMinDuration] = useState(0);
    const [maxDuration, setMaxDuration] = useState(500);

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/program/' + element.id);
        },
        [navigate]
    );

    const handleOpenFilter = useCallback(() => {
        setModalOpen(true);
    }, []);

    const handleCloseFilter = useCallback(() => {
        setModalOpen(false);
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        dispatch(getAllPrograms({ page, take, search: debouncedSearch }));
    }, [allPrograms.length, debouncedSearch, dispatch, page, search, take]);

    return (
        <div className="mb-16 w-full overflow-y-scroll md:h-5/6 lg:mb-0">
            <div className="z-20 flex h-1/6 items-center justify-center bg-black p-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input input-bordered w-full rounded-full border p-2"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-2 top-3">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
                <button
                    onClick={handleOpenFilter}
                    className="shadow-glow ml-2 flex items-center rounded-full border-secondary bg-primary px-4 py-2 text-secondary"
                >
                    <Filter />
                    <span className="ml-2">Filter</span>
                </button>
                <div className={`modal ${modalOpen ? 'modal-open' : ''}`}>
                    <div className="modal-box border border-secondary">
                        <button onClick={() => setModalOpen(false)} className="absolute right-2 top-2 p-2">
                            <Close />
                        </button>
                        <h3 className="text-center text-lg font-bold">Filter Programs</h3>
                        <div className="mt-4">
                            <label className="flex w-full justify-between">
                                <span>Duration Range</span>
                                <span>
                                    {minDuration} - {maxDuration}
                                </span>
                            </label>
                            <Slider
                                className={'flex h-6 items-center'}
                                thumbClassName="bg-white w-6 h-6 rounded-full border-2 border-secondary"
                                defaultValue={[0, 500]}
                                value={[minDuration, maxDuration]}
                                onChange={(value) => {
                                    setMinDuration(value[0]);
                                    setMaxDuration(value[1]);
                                }}
                                renderThumb={({ key, ...props }, state) => <div key={key} {...props}></div>}
                                minDistance={10}
                                renderTrack={({ key, ...props }, state: { index }) => (
                                    <div
                                        key={key}
                                        {...props}
                                        className={`h-1 rounded-full ${state.index === 1 ? 'bg-secondary' : 'bg-neutral-content'}`}
                                    />
                                )}
                                min={0}
                                max={500}
                                step={10}
                            />
                        </div>
                        <div className="modal-action">
                            <button onClick={handleCloseFilter} className="btn btn-outline btn-secondary">
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex h-5/6 w-full flex-col items-center space-y-4 overflow-y-scroll p-2 pb-16 lg:pb-0">
                {loading
                    ? [0, 1, 2].map((val) => (
                          <div key={val} className="skeleton h-48 w-full bg-secondary/20 lg:h-64"></div>
                      ))
                    : allPrograms.map((program, index) => (
                          <div
                              onClick={() => handleSelectElement(program)}
                              id={index.toString()}
                              key={index}
                              className={`flex w-full cursor-pointer justify-center rounded-lg`}
                          >
                              <div
                                  className="hover:shadow-glow card relative h-full w-full border border-neutral-800 shadow-xl hover:border-secondary"
                                  style={{
                                      backgroundImage: `url(${program.cover_image})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center 20%',
                                  }}
                              >
                                  <div className="card-body z-10 h-48 items-start justify-center p-4 lg:h-64">
                                      <h2 className="text-lg text-white">{program.title}</h2>
                                      <div className="card-actions flex-col items-start">
                                          <div className="flex items-center rounded-full border border-white bg-black/50">
                                              <div className="flex items-center px-2 text-sm">
                                                  <Fire />
                                                  <span className="text-xs">
                                                      Difficulty: x {program.program_difficulty}
                                                  </span>
                                              </div>
                                              <div className="flex items-center px-2">
                                                  <Clock />
                                                  <span className="text-xs">{program.program_duration % 60} min</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

export default ProgramsLibrary;
