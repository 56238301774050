import { Clock, Fire } from 'icons';
import Close from 'icons/close';
import Filter from 'icons/filter';
import Loupe from 'icons/loupe';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slider';
import { AppDispatch, RootState } from 'redux/store';
import { getAllExercises, getAllMuscles, getAllTypes } from '../../redux/actions/exerciseActions';
import { getAllWorkouts } from '../../redux/actions/workoutActions';

const WorkoutsLibrary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { allWorkouts, loading } = useSelector((state: RootState) => state.workouts);
    const { muscles, types, exercises, loading: loadingExercises } = useSelector((state: RootState) => state.exercise);
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const [modalOpen, setModalOpen] = useState(false);
    const [minDuration, setMinDuration] = useState(0);
    const [maxDuration, setMaxDuration] = useState(500);
    const [bodyPart, setBodyPart] = useState([]);
    const [type, setType] = useState<string>();
    const [name, setName] = useState<string>();

    const handleSelectElement = useCallback(
        (element: any) => {
            navigate('/workout/' + element.id);
        },
        [navigate]
    );

    const handleOpenFilter = useCallback(() => {
        setModalOpen(true);
        muscles.length === 0 && dispatch(getAllMuscles());
        types.length === 0 && dispatch(getAllTypes());
        exercises.length === 0 && dispatch(getAllExercises());
    }, [dispatch, exercises.length, muscles.length, types.length]);

    const handleCloseFilter = useCallback(() => {
        setModalOpen(false);
        dispatch(
            getAllWorkouts({
                page,
                take,
                search: debouncedSearch,
                min_duration: minDuration,
                max_duration: maxDuration,
                categories: bodyPart,
            })
        );
    }, [bodyPart, debouncedSearch, dispatch, maxDuration, minDuration, page, take]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    useEffect(() => {
        dispatch(getAllWorkouts({ page, take, search: debouncedSearch }));
    }, [dispatch, page, take, debouncedSearch, allWorkouts.length]);

    return (
        <div className="mb-16 w-full overflow-y-scroll md:h-5/6 lg:mb-0">
            <div className="z-20 flex h-1/6 items-center justify-center bg-black p-4">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input input-bordered w-full rounded-full border p-2"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="swap swap-rotate absolute right-2 top-3">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="m-2 h-3 w-3" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
                <button
                    onClick={handleOpenFilter}
                    className="shadow-glow ml-2 flex items-center rounded-full border-secondary bg-primary px-4 py-2 text-secondary"
                >
                    <Filter />
                    <span className="ml-2">Filter</span>
                </button>
                <div className={`modal ${modalOpen ? 'modal-open' : ''}`}>
                    <div className="modal-box border border-secondary">
                        <button onClick={() => setModalOpen(false)} className="absolute right-2 top-2 p-2">
                            <Close />
                        </button>
                        <h3 className="text-center text-lg font-bold">Filter Workouts</h3>
                        <div className="mt-4">
                            <label className="flex w-full justify-between">
                                <span>Duration Range</span>
                                <span>
                                    {minDuration} - {maxDuration}
                                </span>
                            </label>
                            <Slider
                                className={'flex h-6 items-center'}
                                thumbClassName="bg-white w-6 h-6 rounded-full border-2 border-secondary"
                                defaultValue={[0, 500]}
                                value={[minDuration, maxDuration]}
                                onChange={(value) => {
                                    setMinDuration(value[0]);
                                    setMaxDuration(value[1]);
                                }}
                                renderThumb={({ key, ...props }, state) => <div key={key} {...props}></div>}
                                minDistance={10}
                                renderTrack={({ key, ...props }, state: { index }) => (
                                    <div
                                        key={key}
                                        {...props}
                                        className={`h-1 rounded-full ${state.index === 1 ? 'bg-secondary' : 'bg-neutral-content'}`}
                                    />
                                )}
                                min={0}
                                max={500}
                                step={10}
                            />
                        </div>
                        <div className="mt-4">
                            <label>Body Part</label>
                            <div className="grid grid-cols-4 gap-2">
                                {loadingExercises
                                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((val) => (
                                          <div key={val} className="skeleton h-6 w-12 bg-secondary/20"></div>
                                      ))
                                    : muscles.map((muscle, index) => (
                                          <button
                                              key={index}
                                              onClick={() => {
                                                  if (bodyPart.includes(muscle)) {
                                                      setBodyPart(bodyPart.filter((item) => item !== muscle.name));
                                                  } else {
                                                      setBodyPart([...bodyPart, muscle]);
                                                  }
                                              }}
                                              className={`btn btn-sm textarea-xs rounded-full ${
                                                  bodyPart.includes(muscle) ? 'btn-secondary' : 'btn-outline'
                                              }`}
                                          >
                                              {muscle.name}
                                          </button>
                                      ))}
                            </div>
                        </div>
                        <div className="mt-4 flex flex-col">
                            <label>Exercise Type</label>
                            <select onChange={(e) => setType(e.target.value)} className="input">
                                <option value={null}>All</option>
                                {types.map((type, index) => (
                                    <option value={type.name}>{type.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-4 flex flex-col">
                            <label>Exercise Name</label>
                            <select onChange={(e) => setType(e.target.value)} className="input">
                                <option value={null}>All</option>
                                {exercises.map((exercise, index) => (
                                    <option value={exercise.name}>{exercise.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="modal-action">
                            <button onClick={handleCloseFilter} className="btn btn-outline btn-secondary">
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid h-5/6 grid-cols-2 gap-4 overflow-y-scroll p-2 pb-16 lg:grid-cols-4">
                {loading
                    ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((val) => (
                          <div key={val} className="skeleton h-72 w-full bg-secondary/20"></div>
                      ))
                    : allWorkouts.map((workout, index) => (
                          <div
                              onClick={() => handleSelectElement(workout)}
                              key={index}
                              className="card relative flex h-72 cursor-pointer items-center justify-end rounded border border-neutral-800 p-2 hover:border-secondary"
                              style={{
                                  backgroundImage: `url(${workout.cover_image})`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'top',
                              }}
                          >
                              <div className="absolute inset-0 z-0 h-full w-full bg-gradient-to-t from-black to-transparent to-50%"></div>
                              <p className="z-10 p-4 text-center capitalize">{workout.title}</p>
                              <div className="card-actions flex-col items-center justify-end">
                                  <div className="z-10 flex items-center space-x-2 rounded-full border border-white bg-black/30 px-2">
                                      <div className="flex items-center justify-center">
                                          <Fire />
                                          <span className="text-xs">Difficulty: x {workout.difficulty}</span>
                                      </div>
                                      <div className="flex items-center">
                                          <Clock />
                                          <span className="text-xs">{workout.workout_duration % 60} min</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      ))}
            </div>
        </div>
    );
};

export default WorkoutsLibrary;
