import DailyWorkout from 'icons/navbar/daily-workouts';
import Home from 'icons/navbar/home';
import Library from 'icons/navbar/library';
import Profile from 'icons/navbar/profile';
import Settings from 'icons/navbar/settings';
import { useLocation, useNavigate } from 'react-router-dom';

const DesktopNav = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className="absolute left-0 z-30 hidden h-full flex-col justify-start lg:flex">
            <div className="mt-24 rounded-r-lg bg-[#131313]">
                <ul className="flex flex-col items-center space-y-6 py-2">
                    <li className={`p-2 ${location.pathname === '/' ? 'border-r-2 border-secondary' : ''}`}>
                        <button
                            onClick={() => navigate('/')}
                            className={`flex flex-col items-center hover:text-secondary ${location.pathname === '/' ? 'active text-secondary' : ''}`}
                        >
                            <Home fill="currentColor" />
                        </button>
                    </li>
                    <li
                        className={`p-2 ${location.pathname.includes('/library') ? 'border-r-2 border-secondary' : ''}`}
                    >
                        <button
                            onClick={() => navigate('/library/workouts')}
                            className={`flex flex-col items-center hover:text-secondary ${location.pathname.includes('/library') ? 'active text-secondary' : ''}`}
                        >
                            <Library fill="currentColor" />
                        </button>
                    </li>
                    <li
                        className={`p-2 ${location.pathname.includes('/daily-workout') ? 'border-r-2 border-secondary' : ''}`}
                    >
                        <button
                            onClick={() => navigate('/daily-workout')}
                            className={`flex flex-col items-center hover:text-secondary ${location.pathname === '/daily-workout' ? 'active text-secondary' : ''}`}
                        >
                            <DailyWorkout fill="currentColor" />
                        </button>
                    </li>
                    <li
                        className={`p-2 ${location.pathname.includes('/profile') ? 'border-r-2 border-secondary' : ''}`}
                    >
                        <button
                            onClick={() => navigate('/profile')}
                            className={`flex flex-col items-center hover:text-secondary ${location.pathname === '/profile' ? 'active text-secondary' : ''}`}
                        >
                            <Profile fill="currentColor" />
                        </button>
                    </li>
                    <li
                        className={`p-2 ${location.pathname.includes('/settings') ? 'border-r-2 border-secondary' : ''}`}
                    >
                        <button
                            onClick={() => navigate('/settings')}
                            className={`flex flex-col items-center hover:text-secondary ${location.pathname === '/settings' ? 'active text-secondary' : ''}`}
                        >
                            <Settings fill="currentColor" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default DesktopNav;
