import { createSlice } from '@reduxjs/toolkit';


export interface NoitificationState {
    confirmMessage: string
    warningMessage: string
}

const initialState: NoitificationState = {
    confirmMessage: null,
    warningMessage: null,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        confirmNotification: (state, action) => {
            state.confirmMessage = action.payload;
        },
        clearConfirmMessage: (state) => {
            state.confirmMessage = null;
        },
        warningNotification: (state, action) => {
            state.warningMessage = action.payload;
        },
        clearWarningMessage: (state) => {
            state.warningMessage = null
        }
    },
});

export const { confirmNotification, clearConfirmMessage, warningNotification, clearWarningMessage } = notificationSlice.actions;

export default notificationSlice.reducer;
