import CloseIcon from 'assets/img/icons/close.png';
import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const Close = ({ className }: PropsType) => {
    return <img className={cn('', className)} src={CloseIcon} alt="close" />;
};

export default Close;
