import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const Loupe = ({ className }: PropsType) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={cn('', className)}
        >
            <path
                d="M18 18L15.1617 15.1667M11.3684 16.7368C12.7922 16.7368 14.1577 16.1712 15.1645 15.1645C16.1712 14.1577 16.7368 12.7922 16.7368 11.3684C16.7368 9.94463 16.1712 8.57915 15.1645 7.57237C14.1577 6.5656 12.7922 6 11.3684 6C9.94463 6 8.57915 6.5656 7.57237 7.57237C6.5656 8.57915 6 9.94463 6 11.3684C6 12.7922 6.5656 14.1577 7.57237 15.1645C8.57915 16.1712 9.94463 16.7368 11.3684 16.7368Z"
                stroke="#979797"
                strokeWidth="1.6701"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Loupe;
