import { yupResolver } from '@hookform/resolvers/yup';
import FooterPhrase from 'components/FooterPhrase';
import { useReturnPath } from 'pages/auth/layout';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

interface IFormInput {
    purposee: string;
}

const SetPurposeePage = () => {
    const navigate = useNavigate();
    const [showDropDown, setShowDropDown] = useState<boolean>(false);

    const { setPrevPath } = useReturnPath();
    useEffect(() => {
        setPrevPath(null);
    });

    const schema = Yup.object().shape({
        purposee: Yup.string(),
    });

    const { register, handleSubmit, setValue } = useForm<IFormInput>({
        defaultValues: {
            purposee: localStorage.getItem('purposee'),
        },
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const selectedOptionHandler = (e) => {
        setValue('purposee', e.target.textContent);
        setShowDropDown(false);
    };
    const onSubmit = async (value) => {
        localStorage.setItem('purposee', value.purposee);
        navigate('/signup/introduction');
    };

    return (
        <React.Fragment>
            <div className="w-full flex flex-col justify-evenly items-center h-full">
                <div className="flex flex-col items-center w-10/12">
                    <p className="capitalize text-2xl">What's your purposee</p>
                    <p className="capitalize">For commiting to fitness?</p>
                </div>
                <div className="flex flex-col items-center justify-center w-10/12">
                    <p className="capitalize text-center">Constantly reminding yourself why you're doing this</p>
                    <p className="capitalize text-secondary text-center">
                        Will help you push through and stay comitted to improving
                    </p>
                </div>
                <form className="w-10/12 relative form-control">
                    <input
                        onClick={() => setShowDropDown(!showDropDown)}
                        tabIndex={0}
                        type="text"
                        placeholder="Type / Select"
                        className="select select-secondary select-ghost select-bordered select-md rounded-full w-full mb-12"
                        {...register('purposee')}
                    />
                    {showDropDown && (
                        <div tabIndex={0} id="dropdown" className="absolute w-full flex justify-center bottom-full">
                            <ul className="w-5/6 rounded border-t border-r border-l border-secondary p-2 max-h-60 overflow-y-auto bg-base-100">
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am confident in my appearance and abilities.
                                </li>
                                <div className="divider my-0"></div>
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am becoming stronger and more capable every day.
                                </li>
                                <div className="divider my-0"></div>
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am energized and less fatigued.
                                </li>
                                <div className="divider my-0"></div>
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am focused and mentally sharp.
                                </li>
                                <div className="divider my-0"></div>
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am calm and less stressed.
                                </li>
                                <div className="divider my-0"></div>
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am achieving my goals and pushing myself further.
                                </li>
                                <div className="divider my-0"></div>
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am setting a positive example for my friends and family.
                                </li>
                                <div className="divider my-0"></div>
                                <li
                                    onClick={(e) => selectedOptionHandler(e)}
                                    className="p-1 hover:text-secondary rounded-full cursor-pointer"
                                >
                                    I am living a long, healthier life
                                </li>
                            </ul>
                        </div>
                    )}
                    <div className="w-full flex justify-between">
                        <button
                            onClick={() => navigate('/signup/set-activity')}
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                        >
                            {'< Back'}
                        </button>
                        <button
                            type="submit"
                            className="btn hover:btn-secondary btn-outline rounded-full px-8"
                            onClick={handleSubmit(onSubmit)}
                        >
                            {'Next >'}
                        </button>
                    </div>
                    <Link to="/signup/introduction" className="underline text-center text-sm font-normal text-gray-600">
                        skip
                    </Link>
                </form>
            </div>
            <FooterPhrase
                text={
                    <p>
                        He who has a why can hear amost any how.
                        <span className="text-secondary">- Friedrich Nietzsche</span>
                    </p>
                }
                step={5}
            />
        </React.Fragment>
    );
};

export default SetPurposeePage;
