import WelcomeImage from 'assets/img/signup/welcome.png';
import FooterPhrase from 'components/FooterPhrase';
import WarningNotification from 'components/WarningNotification';
import { useReturnPath } from 'pages/auth/layout';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import { registerUser } from '../../redux/actions/authActions';
import { warningNotification } from '../../redux/notificationSlice';

const WelcomePage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { setPrevPath } = useReturnPath();
    const { newUser, loading, error } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        setPrevPath('/signup/weight-selection');
    });

    useEffect(() => {
        newUser &&
            dispatch(
                registerUser({
                    gender: newUser.gender,
                    full_name: newUser.full_name,
                    password: newUser.password,
                    username: newUser.username,
                    email: newUser.email,
                    date_of_birth: newUser.date_of_birth,
                    activity_level: newUser.activity_level,
                    goal: newUser.goal,
                    workout_length: newUser.workout_length,
                    weight: newUser.weight,
                    height: newUser.height,
                    profile_picture: newUser.profile_picture,
                    stripe_id: newUser.stripe_id,
                    purpose: newUser.purpose,
                })
            );
    }, [dispatch, newUser]);

    useEffect(() => {
        if (error !== 'User already exists') dispatch(warningNotification(error));
        if (error && error.includes('User already exists'))
            dispatch(warningNotification('User already exists. Please sign in'));
    }, [dispatch, error]);

    return (
        <div className="flex h-full w-full flex-col items-center justify-between font-semibold text-white">
            <WarningNotification />
            <div className="flex h-1/4 items-center justify-center">
                <img src={WelcomeImage} className="w-1/2" alt="Welcome" />
            </div>
            <span className="text-2xl font-bold text-white">Welcome</span>
            <div className="flex w-2/3 flex-col items-center justify-center text-sm font-normal">
                <p className="text-center">
                    Use This App At A <span className="text-secondary">Gym Or At Home</span> if You Have Dumbbells.
                </p>
                <p className="text-center">
                    SweatSonic Replaces You Need For A <span className="text-secondary">Personal Trainer</span>.
                </p>
                <p className="text-center">
                    Just Put On Your Headphones And <span className="text-secondary">Follow The Music</span>.
                </p>
            </div>
            {error ? (
                <Link
                    to={'/auth/signup'}
                    className={`btn btn-outline btn-wide ${loading ? 'btn-disabled' : ''} rounded-full`}
                >
                    Go Back
                </Link>
            ) : (
                <Link
                    to={'/signup/choose-plan'}
                    className={`btn btn-outline btn-wide ${loading ? 'btn-disabled' : ''} rounded-full`}
                >
                    {loading ? <span className="loading loading-dots loading-md"></span> : 'Continue'}
                </Link>
            )}
            <FooterPhrase text={'Get Ready For Progress'} step={8} />
        </div>
    );
};

export default WelcomePage;
