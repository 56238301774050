import { cn } from 'util/cn';

type PropsType = {
    fill?: string;
    className?: string;
};

const Clock = ({ fill = 'none', className }: PropsType) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            className={cn('', className)}
        >
            <path
                d="M5.83048 11.0001C7.94599 11.0001 9.66095 9.2851 9.66095 7.16959C9.66095 5.05407 7.94599 3.33911 5.83048 3.33911C3.71496 3.33911 2 5.05407 2 7.16959C2 9.2851 3.71496 11.0001 5.83048 11.0001Z"
                stroke="#05BEF8"
                strokeWidth="0.8"
            />
            <path
                d="M4.14453 1.5H7.0326"
                stroke="#05BEF8"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.58594 5.37061V7.77313C5.58594 7.77585 5.58689 7.77846 5.58863 7.78055L6.78928 9.22135"
                stroke="#05BEF8"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Clock;
