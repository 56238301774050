import axios from "axios";
import config from "config/config";

const connection = axios.create({
  baseURL: config.defaults.api_url,
});

const setupAxiosInterceptors = (getAccessToken: () => string | null, getRefreshToken: () => string | null, onTokensRefreshed: (accessToken: string, refreshToken: string) => void, onError: (error: any) => void) => {
  connection.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      console.log(error.response.status);
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          try {
            const response = await axios.post(`${config.defaults.api_url}/auth/refresh-token`, { refresh_token: refreshToken });
            const { accessToken, refreshToken: newRefreshToken } = response.data;
            onTokensRefreshed(accessToken, newRefreshToken);
            originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
            return connection(originalRequest);
          } catch (refreshError) {
            onError(refreshError);
            return Promise.reject(refreshError);
          }
        }
      }
      return Promise.reject(error);
    }
  );


  connection.interceptors.request.use(
    (config) => {
      const token = getAccessToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};



export { connection, setupAxiosInterceptors };
