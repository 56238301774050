import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllVideos, getFeaturedVideos, logWeight } from './actions/videoActions';

export interface Video {
    id: number;
    musicArtist: string;
    setCount: string;
    repCount: string;
    title: string;
    points: number;
    video_duration: number;
    brief: string;
    published: boolean;
    published_at: string;
    created_at: string;
    updated_at: string;
    external_link: string;
    type_of_video: string;
    featured: boolean;
    video_thumbnail_image: string;
    video_url: string;
    exercise_explained_video_url: string;
    video_filename: string;
    exercise_explained_video_filename: string;
    workout_stream_url: string;
    exercise_explained_stream_url: string;
    media_convert_job_status: string;
}

export interface VideoState {
    allVideos: Video[]
    featuredVideos: Video[]
    loading: boolean
    error: string
}

const initialState: VideoState = {
    allVideos: [],
    featuredVideos: [],
    loading: false,
    error: null
};

const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllVideos.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getAllVideos.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.allVideos = action.payload.videos
                state.error = null
            })
            .addCase(getAllVideos.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getFeaturedVideos.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getFeaturedVideos.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.featuredVideos = action.payload
                state.error = null
            })
            .addCase(getFeaturedVideos.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(logWeight.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(logWeight.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                console.log(action)
                state.error = null
            })
            .addCase(logWeight.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
    }
});

export const { } = videoSlice.actions;

export default videoSlice.reducer;
