import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllExercises, getAllMuscles, getAllTypes } from './actions/exerciseActions';

export interface ExerciseType {
    id: number,
    name: string
}

export interface Exercise {
    id: number,
    name: string,
    exerciseType: ExerciseType
}

export interface Muscle {
    id: string,
    name: string,
}

export interface Type {
    id: string,
    name: string,
}

export interface ExerciseState {
    muscles: Muscle[]
    types: Type[]
    exercises: Exercise[]
    loading: boolean
    error: string
}

const initialState: ExerciseState = {
    muscles: [],
    types: [],
    exercises: [],
    loading: false,
    error: null
};

const exerciseSlice = createSlice({
    name: 'exercise',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllExercises.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getAllExercises.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.exercises = action.payload
                state.error = null
            })
            .addCase(getAllExercises.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getAllMuscles.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getAllMuscles.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.muscles = action.payload
                state.error = null
            })
            .addCase(getAllMuscles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(getAllTypes.pending, (state) => {
                state.loading = true;
                state.error = null
            })
            .addCase(getAllTypes.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.types = action.payload
                state.error = null
            })
            .addCase(getAllTypes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    }
});

export const { } = exerciseSlice.actions;

export default exerciseSlice.reducer;
