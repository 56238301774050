import { Link } from 'react-router-dom';
import BackIcon from '../assets/img/icons/back-icon.png';
import ICLogo from '../assets/img/logo.png';
import AppLogo from '../assets/img/logotype.png';

const Hero = (props: { prevPath?: string }) => {
    const { prevPath } = props;
    return (
        <div className="flex h-full w-full items-center">
            {prevPath && (
                <Link to={prevPath} className="absolute left-10 top-10">
                    <div className="rounded-full bg-black">
                        <img alt="back" src={BackIcon} className="w-18" />
                    </div>
                </Link>
            )}
            <div className="flex flex-col items-center text-center">
                <img alt="sweatsonic" src={ICLogo} className="w-1/4" />
                <img alt="sweatsonic" src={AppLogo} className="w-full" />
                <div className="divider divider-secondary my-0" />
                <span className="text-2xl text-white lg:text-4xl">The Music Is Your Trainer</span>
            </div>
        </div>
    );
};
export default Hero;
