import Tabs from 'components/Tabs';
import ExercisesLibrary from 'components/library/ExercisesLibrary';
import InstructionsLibrary from 'components/library/InstructionsLibrary';
import ProgramsLibrary from 'components/library/ProgramsLibrary';
import WorkoutsLibrary from 'components/library/WorkoutsLibrary';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const LibraryPage = () => {
    const { tab } = useParams();
    const [active, setActive] = useState(tab);

    useEffect(() => {
        if (active) {
            window.history.replaceState(null, '', `/library/${active}`);
        }
    }, [active]);

    return (
        <div className="flex h-full w-full flex-col">
            <div className="flex w-full flex-col justify-end pt-4 md:h-1/6 md:pt-0">
                <Tabs
                    tabs={['workouts', 'programs', 'exercises', 'instructions']}
                    active={active}
                    setActive={setActive}
                />
            </div>
            {active === 'workouts' && <WorkoutsLibrary />}
            {active === 'programs' && <ProgramsLibrary />}
            {active === 'exercises' && <ExercisesLibrary />}
            {active === 'instructions' && <InstructionsLibrary />}
        </div>
    );
};

export default LibraryPage;
