import AthletesSection from 'components/home/Athletes';
import DiscoverSection from 'components/home/Discover';
import Tabs from 'components/Tabs';
import { useState } from 'react';

const HomePage = () => {
    const [active, setActive] = useState('discover');

    return (
        <div className="flex h-full w-full flex-col">
            <div className="flex w-full flex-col justify-end pt-4 md:h-1/6 md:pt-0">
                <Tabs tabs={['discover', 'athletes']} active={active} setActive={setActive} />
            </div>
            {active === 'discover' && <DiscoverSection />}
            {active === 'athletes' && <AthletesSection />}
        </div>
    );
};

export default HomePage;
