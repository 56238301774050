import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'components/CheckoutForm';
import FooterPhrase from 'components/FooterPhrase';
import config from 'config/config';
import { useReturnPath } from 'pages/auth/layout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const CheckoutPage = () => {
    const { setPrevPath } = useReturnPath();
    const { loading } = useSelector((state: RootState) => state.user);
    const { clientSecret } = useSelector((state: RootState) => state.user);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        setPrevPath('/signup/choose-plan');
    });

    useEffect(() => {
        setStripePromise(loadStripe(config.stripe_api_key));
    }, []);

    return (
        <div className="h-full flex flex-col justify-evenly">
            {!loading && stripePromise && clientSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm />
                </Elements>
            ) : (
                <div className="w-full h-full flex items-center justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                </div>
            )}
            <FooterPhrase
                text={
                    <p>
                        By placing your order, your confirm you have read, understand and accepted the{' '}
                        <span className="font-bold text-secondary">Sweatsonic</span>™{' '}
                        <a href="www.google.com" className="link link-hover text-secondary underline">
                            Terms and Conditions
                        </a>
                        and
                        <a href="www.google.com" className="link link-hover text-secondary underline">
                            Privacy Policy
                        </a>
                        , and
                        <a href="www.google.com" className="link link-hover text-secondary underline">
                            Medical Disclaimer
                        </a>
                        .
                    </p>
                }
                step={10}
            />
        </div>
    );
};

export default CheckoutPage;
