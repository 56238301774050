import Video from 'components/Video';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';

const TrainerVideoPage = () => {
    const { id } = useParams();
    const { trainerProfile } = useSelector((state: RootState) => state.trainers);

    const handleFinishVideo = useCallback((video, watchedTime) => {}, []);

    const handleVideoTick = useCallback(() => {}, []);

    const handleFinishSession = useCallback(() => {}, []);

    const video = trainerProfile.videos.cadence.find((cadence) => cadence.id === parseInt(id));

    return (
        <Video
            videos={[
                {
                    stream_url: null,
                    video_url: video.video_url,
                    trainer_name: video.trainer_name,
                    title: video.title,
                    thumbnail_image: video.video_thumbnail_image,
                },
            ]}
            videoIndex={0}
            backPath={`/trainer/${video.trainer_id}`}
            autoPlay={true}
            handleFinishSession={handleFinishSession}
            handleFinishVideo={handleFinishVideo}
            handleVideoTick={handleVideoTick}
        />
    );
};

export default TrainerVideoPage;
