import { createAsyncThunk } from '@reduxjs/toolkit';
import { connection } from 'util/axios';

export const getPrograms = createAsyncThunk(
    'program/get',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/programs`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const getAllPrograms = createAsyncThunk(
    'program/all',
    async (data: {
        page: number,
        take: number,
        search: string,
        short_format?: boolean,
    }, { rejectWithValue }) => {
        try {
            const { page, take, search, short_format } = data
            const response = await connection.get(`/programs/all?page=${page}&take=${take}&search=${search}${short_format ? '&short_format=' + short_format : ''}`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const getFeaturedPrograms = createAsyncThunk(
    'program/featured',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/programs`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const getProgramExercises = createAsyncThunk(
    'program/exercises',
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props
            const response = await connection.get(`/programs/${id}/exercises`);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);