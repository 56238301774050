import { Clock } from 'icons';
import Followers from 'icons/followers';
import PlayCircle from 'icons/play-circle';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Video } from 'redux/trainerSlice';
import { secondsToMinutes } from 'util/time';

const TrainerVideosCarousel = (props: { videos: Video[]; show: boolean; loading: boolean }) => {
    const { videos, show, loading } = props;
    const navigate = useNavigate();

    const handleVideoSelect = useCallback(
        (video: Video) => {
            navigate(`/video/${video.id}`);
        },
        [navigate]
    );

    return (
        <div className="carousel w-full h-48">
            {show && !loading ? (
                videos?.map((video, index) => (
                    <div
                        key={index}
                        onClick={() => handleVideoSelect(video)}
                        className="carousel-item w-2/3 lg:w-1/4 cursor-pointer "
                    >
                        <div
                            onClick={() => console.log('onClick card')}
                            className="card h-full relative w-full p-2 hover:border border-secondary"
                        >
                            <img
                                src={video?.video_thumbnail_image}
                                alt="video"
                                className="h-1/2 object-cover rounded-t-lg"
                            />
                            <div className="card-body relative h-1/2 p-2 bg-neutral-900 flex-col items-start justify-start text-xs rounded-b-lg">
                                <p className="flex text-white">
                                    <Clock className="h-full w-4" />
                                    <span className="px-2 self-center">
                                        Duration - {secondsToMinutes(video.video_duration)} MIN
                                    </span>
                                </p>
                                <p className="flex">
                                    <Followers className="h-full" />
                                    <span className="px-2 self-center">Creator: {video.trainer_name}</span>
                                </p>
                                <button className={'absolute right-0 top-0'}>
                                    <PlayCircle className="" />
                                </button>
                                <p className="text-white">{video.title}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex w-full h-48">
                    <div className="p-2 w-1/2 lg:w-1/4 h-full">
                        <div className="skeleton bg-secondary/25 h-full w-full"></div>
                    </div>
                    <div className="p-2 w-1/2 lg:w-1/4 h-full">
                        <div className="skeleton bg-secondary/25 h-full w-full"></div>
                    </div>
                    <div className="p-2 w-1/2 lg:w-1/4 h-full">
                        <div className="skeleton bg-secondary/25 h-full w-full"></div>
                    </div>
                    <div className="p-2 w-1/2 lg:w-1/4 h-full">
                        <div className="skeleton bg-secondary/25 h-full w-full"></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TrainerVideosCarousel;
