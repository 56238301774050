import { Fire } from 'icons';
import Calendar from 'icons/calendar';
import { Link } from 'react-router-dom';

export type ProgramCardProps = {
    id: string;
    title: string;
    image: string;
    description: { difficulty: number; duration: number };
};
export type ProgramsCarouselProps = {
    loading: boolean;
    title?: string;
    elements: ProgramCardProps[];
    handleSelectElement: (element) => void;
};

const ProgramsCarousel = (props: ProgramsCarouselProps) => {
    const { title, elements, loading, handleSelectElement } = props;
    return (
        <div className={`h-2/6 w-full overflow-y-clip lg:h-1/2`}>
            {loading ? (
                <div className="flex h-full w-full flex-col pl-2">
                    <div className="my-2 flex w-5/6 items-center pl-4">
                        <div className="skeleton h-6 w-2/3 bg-secondary/10 px-2"></div>
                    </div>
                    <div className="flex h-full w-full space-x-4 pl-4">
                        <div className={`skeleton w-full bg-secondary/10`}></div>
                    </div>
                </div>
            ) : (
                <div className="flex h-full w-full flex-col items-center justify-center">
                    {title && (
                        <div className="flex w-full justify-between px-4 pt-4">
                            <h1 className="text-xl tracking-wider text-white">{title}</h1>
                            <Link to="/library/programs" className="flex items-center hover:underline">
                                View All
                            </Link>
                        </div>
                    )}
                    <div className="carousel h-full w-full">
                        {elements.map((element, index) => (
                            <div
                                onClick={() => handleSelectElement(element)}
                                id={index.toString()}
                                key={index}
                                className={`carousel-item flex h-full w-11/12 cursor-pointer items-center justify-center rounded-lg`}
                            >
                                <div
                                    className="hover:shadow-glow card relative h-5/6 w-11/12 border-2 border-slate-800 shadow-xl"
                                    style={{
                                        backgroundImage: `url(${element.image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center 20%',
                                    }}
                                >
                                    <div className="card-body z-10 items-start justify-end p-4">
                                        <div className="card-actions flex-col items-start">
                                            <div className="flex items-center space-x-2 rounded-full border border-white bg-black/50 px-4 py-1">
                                                <div className="flex items-center">
                                                    <Fire />
                                                    <p className="text-xs">{element.description.difficulty}</p>
                                                </div>
                                                <div className="flex items-center">
                                                    <Calendar />
                                                    <p className="text-xs">Days {element.description.duration}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="text-2xl text-white">{element.title}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProgramsCarousel;
