type PropsType = {
    fill?: string;
};

const DailyWorkout = ({ fill = 'none' }: PropsType) => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.957 7.90503L16.5942 10.5422L11.2587 15.8778L8.62149 13.2406L13.957 7.90503Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.92789 11.3403C4.29186 10.9763 4.88197 10.9763 5.24594 11.3403L13.1588 19.2531C13.5227 19.6171 13.5227 20.2072 13.1588 20.5712L12.4989 21.231C12.135 21.595 11.5448 21.595 11.1809 21.231L3.26804 13.3182C2.90407 12.9542 2.90407 12.3641 3.26804 12.0001L3.92789 11.3403Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.73453 14.4918C5.0985 14.1278 5.68861 14.1278 6.05258 14.4918L10.0096 18.4488C10.3736 18.8128 10.3736 19.4029 10.0096 19.7669L8.54408 21.2324C8.18011 21.5964 7.59 21.5964 7.22603 21.2324L3.26899 17.2754C2.90502 16.9114 2.90502 16.3213 3.26899 15.9573L4.73453 14.4918Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3895 12.5495C21.0255 12.9134 20.4354 12.9134 20.0714 12.5495L12.1586 4.63664C11.7946 4.27267 11.7946 3.68256 12.1586 3.31859L12.8185 2.65874C13.1824 2.29478 13.7725 2.29478 14.1365 2.65874L22.0493 10.5716C22.4133 10.9355 22.4133 11.5257 22.0493 11.8896L21.3895 12.5495Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3065 9.46781C19.9425 9.83178 19.3524 9.83178 18.9884 9.46781L15.0314 5.51076C14.6674 5.14679 14.6674 4.55668 15.0314 4.19272L16.456 2.76814C16.8199 2.40418 17.41 2.40417 17.774 2.76814L21.7311 6.72519C22.095 7.08916 22.095 7.67927 21.7311 8.04324L20.3065 9.46781Z"
                fill={fill}
            />
        </svg>
    );
};

export default DailyWorkout;
