import { Link } from 'react-router-dom';
import { Video } from '../../../redux/videoSlice';

export type InstructionsCarouselProps = {
    title: string;
    loading: boolean;
    elements: Video[];
    handleSelectElement: (element) => void;
};

const InstructionsCarousel = (props: InstructionsCarouselProps) => {
    const { title, elements, handleSelectElement, loading } = props;
    return (
        <div className={`h-56 w-full overflow-y-clip lg:h-1/2`}>
            {loading ? (
                <div className="flex h-full w-full flex-col pl-2">
                    <div className="my-2 flex w-5/6 items-center pl-4">
                        <div className="skeleton h-6 w-2/3 bg-secondary/10 px-2"></div>
                    </div>
                    <div className="flex h-full w-full space-x-4 pl-4">
                        <div className={`skeleton w-1/2 bg-secondary/10`}></div>
                        <div className={`skeleton w-1/2 bg-secondary/10`}></div>
                    </div>
                </div>
            ) : (
                <div className="flex h-full w-full flex-col items-center justify-center">
                    {title && (
                        <div className="flex w-full justify-between px-4 pt-4">
                            <h1 className="text-xl tracking-wider text-white">{title}</h1>
                            <Link to="/library/instructions" className="flex items-center hover:underline">
                                View All
                            </Link>
                        </div>
                    )}
                    <div className="carousel h-full w-full lg:overflow-x-scroll">
                        {elements.map((element, index) => (
                            <div
                                onClick={() => handleSelectElement(element)}
                                id={index.toString()}
                                key={index}
                                className={`carousel-item flex w-4/12 cursor-pointer items-center justify-center pl-4 lg:w-1/4`}
                            >
                                <div
                                    className="shadow-glow hover:shadow-glow-2 card h-4/6 w-full"
                                    style={{
                                        backgroundImage: `url(${element.video_thumbnail_image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    <div className="absolute inset-0 z-0 h-full w-full rounded-xl bg-gradient-to-t from-black to-transparent to-50%"></div>
                                    <div className="card-body relative items-center justify-center p-4">
                                        <h2 className="z-10 text-white">{element.title}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default InstructionsCarousel;
