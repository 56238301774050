import ProgramsCarousel from 'components/home/discover/ProgramsCarousel';
import WorkoutsCarousel from 'components/home/discover/WorkoutCarousel';
import TrainerVideosCarousel from 'components/trainer/TrainerVideosCarousel';
import Barbell from 'icons/barbell';
import Calendar from 'icons/calendar';
import Followers from 'icons/followers';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import BackIcon from '../../assets/img/icons/back-icon.png';
import ICLogo from '../../assets/img/logo.png';
import Verified from '../../icons/verified';
import { getTrainerProfile } from '../../redux/actions/trainerActions';

const TrainerPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { id } = useParams();
    const { trainerProfile, loading } = useSelector((state: RootState) => state.trainers);

    const handleBack = useCallback(() => {
        navigate('/');
    }, [navigate]);

    useEffect(() => {
        dispatch(getTrainerProfile({ id: parseInt(id) }));
    }, [dispatch, id]);

    return (
        <div className="h-full w-full overflow-y-scroll pb-16 font-['SF-Pro-Display'] lg:pb-0 lg:pt-16">
            <div className="h-0.2 relative flex w-full p-4">
                <button onClick={handleBack} className="absolute top-4 p-2 font-black text-white">
                    <img src={BackIcon} alt="Back Icon" className="h-4" />
                </button>
                <div className="flex w-full items-center justify-center">
                    <p className="h-fit w-fit rounded px-2 text-xl text-white">Athlete</p>
                </div>
            </div>
            <div className="flex h-2/6 flex-col justify-evenly pb-12">
                {loading ? (
                    <div className="skeleton h-full w-full rounded-none bg-secondary/20"></div>
                ) : (
                    <div className="relative flex h-full flex-col items-center justify-end p-4">
                        <img
                            src={trainerProfile?.profile.cover_image}
                            alt="trainer"
                            className="absolute inset-0 h-full w-full object-cover object-[center_30%] md:object-[center_15%]"
                        />
                        <div className="relative z-20 w-11/12 translate-y-1/2 rounded-lg border-4 border-neutral-800 bg-black/80">
                            <img
                                alt="sweatsonic"
                                src={ICLogo}
                                className="absolute right-0 top-0 w-1/4 max-w-24 -translate-y-1/2 rounded-full border-4 border-neutral-800 bg-black/70"
                            />
                            <div className="space-y-2 p-2">
                                <p className="flex text-xl uppercase text-white">
                                    {trainerProfile?.profile.username}
                                    <Verified />
                                </p>
                                <div className="flex space-x-2 text-xs">
                                    <p className="flex">
                                        <Barbell />
                                        <span className="pl-1">{`${trainerProfile?.workouts_count} Exercises`}</span>
                                    </p>
                                    <p className="flex">
                                        <Calendar />
                                        <span className="pl-1">{`${trainerProfile?.profile.monthly_plays} Monthly Plays`}</span>
                                    </p>
                                    <p className="flex">
                                        <Followers />
                                        <span className="pl-1">{`${trainerProfile?.profile.total_followers} Followers`}</span>
                                    </p>
                                </div>
                                <p className="text-sm">{trainerProfile?.profile.description}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="w-full p-4 pb-0 text-center text-white">Favorite Exercises</div>
            <div className="flex w-full flex-col">
                <TrainerVideosCarousel
                    videos={trainerProfile?.videos?.cadence}
                    loading={loading}
                    show={trainerProfile?.videos && trainerProfile?.videos.cadence.length > 0}
                />
            </div>
            <div className="divider w-full"></div>
            <div className="w-full p-4 pb-0 text-center text-white">All Exercises</div>
            <div className="flex w-full flex-col">
                <p className="px-4">Cadence</p>
                <TrainerVideosCarousel
                    videos={trainerProfile?.videos?.cadence}
                    loading={loading}
                    show={trainerProfile?.videos && trainerProfile?.videos.cadence.length > 0}
                />
            </div>
            <div className="flex w-full flex-col">
                <p className="px-4">HIIT</p>
                <TrainerVideosCarousel
                    videos={trainerProfile?.videos?.hitt}
                    loading={loading}
                    show={trainerProfile?.videos && trainerProfile?.videos.hitt.length > 0}
                />
            </div>
            {trainerProfile?.favourite_workouts.length > 0 && (
                <>
                    <div className="divider w-full"></div>
                    <div className="w-full p-4 pb-0 text-center text-white">Favorite Workouts</div>
                    <WorkoutsCarousel
                        loading={loading}
                        elements={
                            trainerProfile?.favourite_workouts.map((workout) => ({
                                id: workout.id.toString(),
                                title: workout.title,
                                image: workout.cover_image,
                                description: {
                                    difficulty: workout.difficulty,
                                    duration: workout.duration,
                                },
                            })) || []
                        }
                        handleSelectElement={() => console.log('handleSelectElement')}
                    />
                </>
            )}
            {trainerProfile?.favourite_programs.length > 0 && (
                <>
                    <div className="divider w-full"></div>
                    <div className="w-full p-4 pb-0 text-center text-white">Favorite Programs</div>
                    <ProgramsCarousel
                        loading={loading}
                        elements={
                            trainerProfile?.favourite_programs.map((program) => ({
                                id: program.id.toString(),
                                title: program.title,
                                image: program.cover_image,
                                description: {
                                    difficulty: program.difficulty,
                                    duration: program.duration,
                                },
                            })) || []
                        }
                        handleSelectElement={() => console.log('handleSelectElement')}
                    />
                </>
            )}
        </div>
    );
};

export default TrainerPage;
