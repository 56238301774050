import { Clock, Fire } from 'icons';
import { Link } from 'react-router-dom';

export type WorkoutCard = {
    id: string;
    title: string;
    image: string;
    description?: { difficulty: number; duration: number };
};

export type WorkoutCarouselProps = {
    title?: string;
    loading: boolean;
    elements: WorkoutCard[];
    handleSelectElement: (element) => void;
};

const WorkoutsCarousel = (props: WorkoutCarouselProps) => {
    const { title, elements, handleSelectElement, loading } = props;

    return (
        <div className={`h-72 w-full overflow-y-clip lg:h-1/2`}>
            {loading ? (
                <div className="flex h-full w-full flex-col pl-2">
                    <div className="my-2 flex w-5/6 items-center pl-4">
                        <div className="skeleton h-6 w-2/3 bg-secondary/10 px-2"></div>
                    </div>
                    <div className="flex h-full w-full space-x-4 pl-4">
                        <div className={`skeleton w-1/2 bg-secondary/10`}></div>
                        <div className={`skeleton w-1/2 bg-secondary/10`}></div>
                    </div>
                </div>
            ) : (
                <div className="flex h-full w-full flex-col items-center justify-center">
                    {title && (
                        <div className="flex w-full justify-between px-4 pt-4">
                            <h1 className="text-xl tracking-wider text-white">{title}</h1>
                            <Link to="/library/workouts" className="flex items-center hover:underline">
                                View All
                            </Link>
                        </div>
                    )}
                    <div className="carousel h-full w-full lg:overflow-x-scroll">
                        {elements.map((element, index) => (
                            <div
                                onClick={() => handleSelectElement(element)}
                                id={index.toString()}
                                key={index}
                                className={`carousel-item flex h-full w-5/12 cursor-pointer items-center justify-center rounded-lg lg:w-2/12`}
                            >
                                <div
                                    className="hover:shadow-glow card relative h-5/6 w-11/12"
                                    style={{
                                        backgroundImage: `url(${element.image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                >
                                    <div className="absolute h-full w-full rounded-xl bg-gradient-to-t from-black to-transparent to-40%"></div>
                                    <div className="card-body relative z-10 items-center justify-end p-4">
                                        <h2 className="capitalize text-white">{element.title}</h2>
                                        {element.description && (
                                            <div className="card-actions flex-col items-center justify-end">
                                                <div className="flex items-center space-x-2 rounded-full border border-white bg-black/50 px-4 py-1">
                                                    <div className="flex items-center">
                                                        <Fire />
                                                        <p className="text-xs">X{element.description.difficulty}</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <Clock />
                                                        <p className="text-xs">
                                                            {element.description.duration % 60} Min
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default WorkoutsCarousel;
