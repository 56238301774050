import { yupResolver } from '@hookform/resolvers/yup';
import ShowPassword from 'icons/show-password';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import * as Yup from 'yup';
import { resetNewUser, setNewUser } from '../../redux/authSlice';
import { useReturnPath } from './layout';

interface IFormInput {
    full_name: string;
    username: string;
    email: string;
    password: string;
    repeatPassword: string;
}

const SignupPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { setPrevPath } = useReturnPath();
    const [showPassword, setShowPassword] = useState(false);

    const schema = Yup.object().shape({
        full_name: Yup.string().required('Full name is required'),
        username: Yup.string().required('Username is required'),
        email: Yup.string().email('Email is invalid').required('Email is required'),
        password: Yup.string()
            .required('Password is required')
            .matches(/[!+@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`),
        repeatPassword: Yup.string().required('Password is required'),
    });

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema) as any,
    });

    const onSubmit = useCallback(
        async (values: IFormInput) => {
            console.log(values);
            if (values.password !== values.repeatPassword) {
                setError('repeatPassword', { type: 'custom', message: 'Passwords do not match' });
                return;
            }
            dispatch(setNewUser(values));
            navigate('/signup/set-profile');
        },
        [dispatch, navigate, setError]
    );

    useEffect(() => {
        setPrevPath('/auth');
    }, [dispatch, setPrevPath]);

    useEffect(() => {
        dispatch(resetNewUser());
    }, [dispatch]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full h-full flex flex-col items-center justify-evenly form-control"
        >
            <p className="label text-lg font-bold text-white">Create New Account</p>
            <div className="flex flex-col w-10/12">
                <div className={'w-full flex flex-col items-center relative'}>
                    <input
                        type="text"
                        placeholder="Name"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.full_name ? 'border-warning' : ''}`}
                        {...register('full_name')}
                    />
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.full_name && <span className="text-xs">{errors.full_name?.message}</span>}
                    </div>
                </div>
                <div className={'w-full flex flex-col items-center relative'}>
                    <input
                        type="text"
                        placeholder="Username"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.username ? 'border-warning' : ''}`}
                        autoComplete="username"
                        {...register('username')}
                    />
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.username && <span className="text-xs">{errors.username?.message}</span>}
                    </div>
                </div>
                <div className={'w-full flex flex-col items-center relative'}>
                    <input
                        type="text"
                        placeholder="Email"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.email ? 'border-warning' : ''}`}
                        autoComplete="email"
                        {...register('email')}
                    />
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.email && <span className="text-xs">{errors.email?.message}</span>}
                    </div>
                </div>
                <div className={'w-full flex flex-col items-center relative'}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.password ? 'border-warning' : ''}`}
                        autoComplete="new-password"
                        {...register('password')}
                    />
                    <label className="absolute right-2 top-2 swap swap-rotate">
                        <input
                            type="checkbox"
                            checked={showPassword}
                            className="hidden"
                            onChange={() => setShowPassword(!showPassword)}
                        />
                        <ShowPassword className={`swap-on h-10 w-7 fill-current text-secondary`} />
                        <ShowPassword className={`swap-off h-10 w-7 fill-current`} />
                    </label>
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.password && <span className="text-xs">{errors.password?.message}</span>}
                    </div>
                </div>
                <div className={'w-full flex flex-col items-center relative'}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Repeat Password"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full ${errors.repeatPassword ? 'border-warning' : ''}`}
                        autoComplete="repeat-password"
                        {...register('repeatPassword')}
                    />
                    <label className="absolute right-2 top-2 swap swap-rotate">
                        <input
                            type="checkbox"
                            checked={showPassword}
                            className="hidden"
                            onChange={() => setShowPassword(!showPassword)}
                        />
                        <ShowPassword className={`swap-on h-10 w-7 fill-current text-secondary`} />
                        <ShowPassword className={`swap-off h-10 w-7 fill-current`} />
                    </label>
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.repeatPassword && <span className="text-xs">{errors.repeatPassword?.message}</span>}
                    </div>
                </div>
            </div>
            <p className="text-sm p-2">
                Already have an Account?{' '}
                <Link to={'/signin'} className="text-secondary underline">
                    Sign In
                </Link>
            </p>
            <div className="flex justify-center w-1/2">
                <button type="submit" className="btn hover:btn-secondary btn-outline rounded-full btn-wide">
                    Create
                </button>
            </div>
        </form>
    );
};

export default SignupPage;
