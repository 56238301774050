import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { completeBilling } from '../redux/actions/userActions';

const CheckoutForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            setIsProcessing(true);

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/signup/set-profile-picture`,
                },
            });

            if (error) {
                setIsProcessing(false);
                setError(error);
            } else {
                dispatch(completeBilling());
            }
        },
        [dispatch, elements, stripe]
    );
    return (
        <form onSubmit={handleSubmit} className="w-full h-full flex flex-col items-center justify-center form-control">
            <p className="label font-bold text-white">Payment Details</p>
            <PaymentElement />
            <button
                disabled={isProcessing}
                type="submit"
                className="btn hover:btn-secondary btn-outline rounded-full btn-wide my-2"
            >
                {isProcessing ? 'Processing...' : 'Continue'}
            </button>
            <span className="text-error">{error}</span>
        </form>
    );
};

export default CheckoutForm;
