import Close from 'icons/close';
import Loupe from 'icons/loupe';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'redux/store';
import { Trainer } from 'redux/trainerSlice';

const AthletesSection = () => {
    const navigate = useNavigate();
    const { loading: loadingTrainers, trainers } = useSelector((state: RootState) => state.trainers);
    const [search, setSearch] = useState('');

    const handleSelectTrainer = useCallback(
        (element: Trainer) => {
            navigate('/trainer/' + element.id);
        },
        [navigate]
    );
    return (
        <div className="md:h-5/6">
            <div className="flex items-center justify-center p-4 z-20 bg-black md:h-1/6">
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="input input-bordered border p-2 rounded-full w-full"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                    />
                    <label className="absolute right-2 top-3 swap swap-rotate">
                        {search.length > 0 ? (
                            <button onClick={() => setSearch('')}>
                                <Close className="h-3 w-3 m-2" />{' '}
                            </button>
                        ) : (
                            <Loupe />
                        )}
                    </label>
                </div>
            </div>
            <div className="h-5/6 w-full grid grid-cols-2 gap-4 p-2 lg:grid-cols-4 overflow-y-scroll mb-16 lg:mb-0">
                {loadingTrainers ? (
                    <>
                        <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                        <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                        <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                        <div className="skeleton h-72 px-2 bg-secondary/10"></div>
                    </>
                ) : (
                    trainers.map((trainer, index) => (
                        <div
                            key={index}
                            className="relative rounded h-72 card flex justify-end items-center"
                            style={{
                                backgroundImage: `url(${trainer.cover_image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'top',
                            }}
                            onClick={() => handleSelectTrainer(trainer)}
                        >
                            <div className="absolute w-full h-full inset-0 bg-gradient-to-t from-black to-transparent z-0"></div>
                            <p className="capitalize text-center p-4 z-10">{trainer.username}</p>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default AthletesSection;
