import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { connection } from 'util/axios';

export const login = createAsyncThunk(
    'auth/login',
    async (props: { email: string; password: string }, { rejectWithValue }) => {
        try {
            const data = {
                email: props.email,
                password: props.password,
            };
            const response = await connection.post(`/auth/login`, data);
            Cookies.set("access_token", response.data.results.access_token);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Login failed');
        }
    }
);

export const registerUser = createAsyncThunk(
    'auth/register',
    async (data: { full_name: string, password: string, username: string, email: string, date_of_birth: string, gender: string, goal: string, activity_level: string, weight: string, height: string, profile_picture: string, stripe_id: string, purpose: string, workout_length: string }, { rejectWithValue }) => {
        try {
            console.log(data)
            const response = await connection.post(`/auth/register`, data);
            return response.data.results;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Register failed');
        }
    }
)

export const getCurrentUser = createAsyncThunk(
    'auth/current',
    async (_, { rejectWithValue }) => {
        try {
            const response = await connection.get(`/auth/current/user`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'Fetch failed');
        }
    }
);

export const oauthLogin = createAsyncThunk(
    'auth/oauth',
    async (data: { provider: string, token: string }, { rejectWithValue }) => {
        try {
            const response = await connection.post(`/auth/refresh-token`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || 'oAuth failed');
        }
    }
);
