import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from 'redux/store';
import * as yup from 'yup';
import { changePassword } from '../../redux/actions/userActions';
import { confirmNotification, warningNotification } from '../../redux/notificationSlice';
import { setResetPasswordEmail } from '../../redux/userSlice';
import { useReturnPath } from './layout';

interface IFormInput {
    email: string;
}

const ForgotPasswordPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {
        resetPasswordState: { loading, error, responseMessage, active },
    } = useSelector((state: RootState) => state.user);
    const { setPrevPath } = useReturnPath();

    const schema = yup.object().shape({
        email: yup.string().required('Email/Username is required'),
    });

    useEffect(() => {
        setPrevPath('/auth');
    }, [setPrevPath]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const onSubmit: SubmitHandler<IFormInput> = useCallback(
        (data) => {
            dispatch(setResetPasswordEmail(data.email));
            dispatch(changePassword(data));
        },
        [dispatch]
    );

    useEffect(() => {
        active && navigate('/auth/change-password');
    }, [active, navigate]);

    useEffect(() => {
        dispatch(confirmNotification(responseMessage));
    }, [responseMessage, dispatch, navigate]);

    useEffect(() => {
        dispatch(warningNotification(error));
    }, [error, dispatch]);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full h-full flex flex-col items-center justify-center form-control"
        >
            <p className="label text-xl text-white font-semibold">Forgot Password</p>
            <div className="flex flex-col w-10/12 justify-evenly">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Email"
                        autoComplete="username"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full relative ${errors.email ? ' border-warning' : ''}`}
                        {...register('email')}
                    />
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.email && <span className="text-xs">{errors.email?.message}</span>}
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center p-2">
                <button
                    type="submit"
                    className={`btn hover:btn-secondary btn-outline rounded-full w-8/12`}
                    disabled={loading}
                >
                    {loading ? 'Sending...' : 'Send'}
                </button>
            </div>
        </form>
    );
};

export default ForgotPasswordPage;
