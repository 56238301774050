import { useEffect, useState } from 'react';
import { WorkoutVideo } from '../redux/workoutSlice';

const LogWeightCard = (props: {
    watchedTime: number;
    video: WorkoutVideo;
    setData: (videoId, weight, totalWeight) => void;
}) => {
    const { watchedTime, video, setData } = props;
    const [weight, setWeight] = useState<boolean>(false);
    const [totalWeight, setTotalWeight] = useState<number>(0);

    useEffect(() => {
        setData(watchedTime, video.id, totalWeight);
    }, [weight, totalWeight, setData, video.id, watchedTime]);

    return (
        <div className="card h-full flex-row w-10/12 border-secondary border-2 p-4 relative">
            <span className="absolute top-0 right-0 text-sm p-2">
                {Math.floor(watchedTime / 60)}:{watchedTime % 60 < 10 ? `0${watchedTime % 60}` : watchedTime % 60} min
            </span>
            <figure className="w-1/3 relative">
                <img className="object-cover h-full z-0" src={video.video_thumbnail_image} alt={video.title} />
                <div className="w-full h-full bg-gradient-to-t from-black to-transparent top-0 left-0 z-10"></div>
            </figure>
            <div className="card-body p-1 w-2/3 z-10">
                <h2 className="card-title text-base p-2">{video.title}</h2>
                <div className="flex items-center justify-between">
                    <span className="text-sm">Select: </span>
                    <div className="flex p-2">
                        <button
                            className={`btn btn-xs rounded-full ${weight ? 'btn-secondary' : 'btn-outline'}`}
                            onClick={() => setWeight(true)}
                        >
                            Weight
                        </button>
                        <button
                            className={`btn btn-xs rounded-full ${!weight ? 'btn-secondary' : 'btn-outline'}`}
                            onClick={() => setWeight(false)}
                        >
                            No Weight
                        </button>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <label className="text-sm px-2">Total: </label>
                    <input
                        type={'number'}
                        className="input input-bordered w-full"
                        onChange={(e) => setTotalWeight(e.target.valueAsNumber)}
                    />
                </div>
            </div>
        </div>
    );
};

export default LogWeightCard;
