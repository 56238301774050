import 'assets/css/global.scss';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { yupResolver } from '@hookform/resolvers/yup';
import ShowPassword from 'icons/show-password';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { login } from '../../redux/actions/authActions';
import { clearAuthState } from '../../redux/authSlice';
import { AppDispatch, RootState } from '../../redux/store';
import { useReturnPath } from './layout';

interface IFormInput {
    email: string;
    password: string;
}

// Define Yup schema for validation
const schema = yup.object().shape({
    email: yup.string().required('Email/Username is required'),
    password: yup.string().required('Password is required'),
});

const SigninPage: React.FunctionComponent = (props) => {
    const { loading, error } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();
    const [showPassword, setShowPassword] = React.useState(false);
    const {
        setError,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        resolver: yupResolver(schema) as any, // Type assertion to resolve the issue
    });

    const { setPrevPath } = useReturnPath();

    useEffect(() => {
        setPrevPath('/auth');
        dispatch(clearAuthState());
    }, [dispatch, setPrevPath]);

    useEffect(() => {
        if (error) {
            setError('email', { type: 'server', message: error });
        }
    }, [error, setError]);

    const onSubmit: SubmitHandler<IFormInput> = useCallback(
        (data) => {
            dispatch(login(data));
        },
        [dispatch]
    );

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full h-full flex flex-col items-center justify-evenly form-control"
        >
            <p className="label text-xl text-white font-semibold">Sign In</p>
            <div className="flex flex-col w-10/12 justify-evenly">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Email / User Name"
                        autoComplete="username"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full relative ${errors.email ? ' border-warning' : ''}`}
                        {...register('email')}
                    />
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.email && <span className="text-xs">{errors.email?.message}</span>}
                    </div>
                </div>
                <div className="relative">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className={`input input-bordered rounded-full focus:outline-secondary w-full relative ${errors.password ? ' border-warning' : ''}`}
                        autoComplete="current-password"
                        {...register('password')}
                    />
                    <label className="absolute right-2 top-2 swap swap-rotate">
                        <input type="checkbox" className="hidden" onChange={() => setShowPassword(!showPassword)} />
                        <ShowPassword className={`swap-on h-10 w-7 fill-current text-secondary`} />
                        <ShowPassword className={`swap-off h-10 w-7 fill-current`} />
                    </label>
                    <div className="w-full text-sm text-error h-4 flex items-center justify-end px-4">
                        {errors.password && <span className="text-xs">{errors.password?.message}</span>}
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center">
                <Link to={'/auth/forgot-password'} className="text-secondary text-sm tracking-wide underline">
                    Forgot Password?
                </Link>
                <p className="text-sm tracking-wide">
                    Don't have an account?{' '}
                    <Link to={'/auth/signup'} className="underline text-secondary">
                        Register Now.
                    </Link>
                </p>
            </div>
            <div className="w-full flex justify-center p-2">
                <button
                    type="submit"
                    className={`btn hover:btn-secondary btn-outline rounded-full w-8/12`}
                    disabled={loading}
                >
                    {loading ? 'Signin in...' : 'Sign In'}
                </button>
            </div>
        </form>
    );
};

export default SigninPage;
