import ShareIcon from 'assets/img/icons/share-icon.png';
import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const Share = ({ className }: PropsType) => {
    return <img className={cn('', className)} src={ShareIcon} alt="share" />;
};

export default Share;
