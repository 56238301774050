import { LegacyRef, useCallback } from 'react';

const VideoPlayer = (props: {
    videoRef: LegacyRef<HTMLVideoElement>;
    currentVideoIndex;
    videoIndex;
    showControls;
    setShowControls;
    setControlsCountDown;
}) => {
    const { videoRef, showControls, currentVideoIndex, videoIndex, setShowControls, setControlsCountDown } = props;

    const handleClick = useCallback(() => {
        !showControls && setControlsCountDown(5);
        setShowControls(!showControls);
    }, [setControlsCountDown, setShowControls, showControls]);

    return (
        <video
            key={videoIndex}
            ref={videoRef}
            height="100vh"
            className={`z-0 ${currentVideoIndex === videoIndex ? '' : 'hidden'} `}
            style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
            }}
            onClick={handleClick}
            playsInline
        />
    );
};

export default VideoPlayer;
