import Logotype from 'icons/logotype';

const DesktopTopBar = () => {
    return (
        <div className="pointer-events-none absolute inset-0 z-40 hidden w-full justify-center lg:flex">
            <div className="shadow-glow flex h-12 w-11/12 items-center justify-center rounded-b-xl border border-l-0 border-t-0 border-secondary border-r-secondary bg-[#131313]">
                <Logotype className="h-5" />
            </div>
        </div>
    );
};

export default DesktopTopBar;
