type PropsType = {
    fill?: string;
};

const Library = ({ fill = 'none' }: PropsType) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1322 21.5038H6.36733C5.13392 21.5038 4.10117 20.5692 3.97834 19.3419L3.25804 12.1389C3.19048 11.4639 3.41162 10.7918 3.86679 10.2888C4.32195 9.78579 4.96866 9.49878 5.64703 9.49878H18.8525C19.5309 9.49878 20.1776 9.78579 20.6328 10.2888C21.0879 10.7918 21.3091 11.4639 21.2415 12.1389L20.5212 19.3419C20.3984 20.5692 19.3657 21.5038 18.1322 21.5038Z"
                fill="currentColor"
            />
            <path
                d="M6.24707 6.49752H18.2521"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.24805 3.4963H16.2514"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.417 13.5H13.417"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Library;
