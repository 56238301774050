import { cn } from 'util/cn';

type PropsType = {
    className?: string;
};

const ShowPassword = ({ className }: PropsType) => {
    return (
        <svg
            width={12}
            height={12}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className={cn('', className)}
        >
            <path
                d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="12"
                cy="12"
                r="3"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ShowPassword;
